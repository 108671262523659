import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { foerderbereichActions } from '../../store/foerderbereich/foerderbereich.actions';
import { Foerderart, Foerderbereich } from '../../model/foerderbereich.model';
import { selectNutzerFoerderbereicheFoerderarten, selectNutzerFoerderbereicheFoerderbereiche, selectNutzerFoerderbereicheOpened } from '../../store/foerderbereich/foerderbereich.selectors';
import { Observable } from 'rxjs';
import { formularActionsInt } from '../../store/formular/formular.actions';
import { AntragsType } from '../../model/formular.model';
import { DEFAULT_DATE_STRING_CONFIG } from '../../model/data-table.model';

/**
 * Component to display all foerderbereiche and foerderarten to link to the active version of a form
 */
@Component({
    selector: 'app-antrag-creation',
    templateUrl: './antrag-creation.component.html',
    styleUrl: './antrag-creation.component.scss',
})
export class AntragCreationComponent implements OnInit {
    private store = inject(Store);

    foerderbereiche$: Observable<Foerderbereich[]>;
    foerderarten$: Observable<Foerderart[]>;
    openFoerderbereiche$: Observable<Record<string, boolean>>;

    /**
     * Get the needed observables from the store
     */
    ngOnInit(): void {
        this.store.dispatch(foerderbereichActions.changeFoerderbereicheUndFoerderarten());
        this.foerderarten$ = this.store.select(selectNutzerFoerderbereicheFoerderarten);
        this.foerderbereiche$ = this.store.select(selectNutzerFoerderbereicheFoerderbereiche);
        this.openFoerderbereiche$ = this.store.select(selectNutzerFoerderbereicheOpened);
    }


    /**
     * the function that takes care of the navigation and triggers the creation of the correct formular url
     *
     * @param shortname the shortname of the form as given by the foerderart
     * @param foerderart the foerderart that should be called placed in the url to keep an overview
     * @param foerderartId the id of the foerderart to be used for creating the antrag
     */
    navigateToFormular(shortName: string, foerderart: string, foerderartId: number): void {
        this.store.dispatch(formularActionsInt.postChangeCreateNewFormular({
            shortName,
            antragsArtId: foerderartId,
            antragsType: AntragsType.Foerderantrag,
            antragsart: foerderart
        }));
    }

    gueltigBisText(gueltigBisDate: string | undefined): string {
        if (gueltigBisDate) {
            return `(Gültig bis: ${new Date(gueltigBisDate).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG)})`;
        }
        return '';
    }
}
