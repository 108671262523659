import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCsvManagementIsProcessing, selectCsvManagementServerValidationMessage, selectCsvManagementServerValidationStatus } from '../../../store/csv-management/csv-management.selectors';
import { selectRole } from '../../../store/auth/auth.selectors';
import { PortalRole } from '../../../model/auth.model';
import { csvManagementActions } from '../../../store/csv-management/csv-management.actions';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-csv-management',
    templateUrl: './csv-management.component.html',
    styleUrl: './csv-management.component.scss',
})
export class CsvManagementComponent implements OnInit {
    private formBuilder = inject(FormBuilder);
    private store = inject(Store);

    csvUploadFormGroup: FormGroup;
    errorMessageValidation: string | null = null;
    validFiles: File[] = [];

    uploadTriggered = false;
    neededUserRole = PortalRole.FFA_ADMIN;

    filesAreProcessing$: Observable<boolean>;
    errorMessageServer$: Observable<string>;
    csvFileHandlingStatus$: Observable<boolean>;
    userPortalRole$: Observable<PortalRole | null>;

    ngOnInit(): void {
        this.store.dispatch(csvManagementActions.resetServerResponse());
        this.csvUploadFormGroup = this.formBuilder.group({
            separator: [',', [Validators.required, Validators.maxLength(1)]],
        });
        this.userPortalRole$ = this.store.select(selectRole);
        this.csvFileHandlingStatus$ = this.store.select(selectCsvManagementServerValidationStatus);
        this.errorMessageServer$ = this.store.select(selectCsvManagementServerValidationMessage);
        this.filesAreProcessing$ = this.store.select(selectCsvManagementIsProcessing);
    }

    /**
     * validates the files when the input field is changed 
     * @param event the chang event of the input field
     */
    onFileSelected(event: Event): void {
        this.uploadTriggered = false;
        this.errorMessageValidation = null;
        const input = event.target as HTMLInputElement;
        if (!input.files) {
            return;
        }

        const files = Array.from(input.files);

        // Check for max files
        if (files.length > environment.csvManagement.csvFilenames.length) {
            this.errorMessageValidation = `Es dürfen nur ${environment.csvManagement.csvFilenames.length} Dateien hochgeladen werden.`;
            return;
        }

        // Validate filenames
        const invalidFiles = files.filter(file => !environment.csvManagement.csvFilenames.includes(file.name));
        if (invalidFiles.length > 0) {
            this.errorMessageValidation = `Ungültige Dateinamen: ${invalidFiles.map(f => f.name).join(', ')}`;
            return;
        }
        files.forEach(file => {
            const positionOfFile = this.validFiles.findIndex((validFile) => validFile.name === file.name);
            // Splice is used here for visual clues for the user, so that the newst files are always at the bottom of list.
            if (positionOfFile !== -1) {
                this.validFiles.splice(positionOfFile, 1);
            }
            this.validFiles.push(file);
        })
    }

    /**
     * triggers the update process on the server
     */
    triggerFileTransfer(): void {
        this.uploadTriggered = true;
        this.store.dispatch(csvManagementActions.changeSendFilesToServer({
            csvFiles: this.validFiles,
            separator: this.csvUploadFormGroup.get('separator')?.value,
        }));
        this.validFiles = [];
    }

    /**
     * Checks if the form is invalid, since it sets disabled to true, it checks if the button should be disabled
     * @returns if the form is invalid
     */
    formIsInvalid(): boolean {
        return this.validFiles.length === 0 || this.csvUploadFormGroup.invalid;
    }

    /**
     * Remove a file or all files from the list for uploading
     * @param filename the name of the file that should be removed or undefined if all files should be removed
     */
    removeValidFile(filename: string | undefined = undefined): void {
        if (filename) {
            this.validFiles = this.validFiles.filter(file => file.name !== filename);
        } else {
            this.validFiles = [];
        }
    }
}
