import { createReducer, on } from "@ngrx/store";
import { MaintenanceScheduleStore } from "../../model/maintenance-schedule.model";
import { maintenanceScheduleActions } from "./maintenance-schedule.actions";

export const maintenanceScheduleInitialState: MaintenanceScheduleStore = {
    maintenanceDate: null,
    maintenanceIsActive: false,
};

export const maintenanceScheduleReducer = createReducer(
    maintenanceScheduleInitialState,
    on(maintenanceScheduleActions.setCheckMaintenanceSchedule, (state, action): MaintenanceScheduleStore => {
        if (action.maintenanceDate === null) {
            return { ...state, maintenanceDate: null, maintenanceIsActive: false };
        } else {
            const maintenanceStartMs = action.maintenanceDate.maintenanceStartDate.valueOf();
            const maintenanceEndMs = action.maintenanceDate.maintenanceEndDate.valueOf();
            const nowMs = Date.now();
            if (maintenanceStartMs > nowMs) {
                return { ...state, maintenanceDate: action.maintenanceDate, maintenanceIsActive: false };
            } else if (maintenanceEndMs > nowMs) {
                return { ...state, maintenanceDate: action.maintenanceDate, maintenanceIsActive: true };
            } else {
                return { ...state, maintenanceDate: null, maintenanceIsActive: false };
            }
        }
    })
)