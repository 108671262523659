import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

/**
 * A general Item to select a date and time
 */
@Component({
    selector: 'app-date-time-input-group',
    templateUrl: './date-time-input-group.component.html',
    styleUrl: './date-time-input-group.component.scss',
})
export class DateTimeInputGroupComponent implements OnInit {
    @Input() startDate = new Date();
    @Input() minuteIntervall = 30;
    @Input() dateLabel = "Datum";
    @Input() timeLabel = "Zeit";
    @Input() groupLabel = "";
    @Output() dateTimeEvent = new EventEmitter<Date>();

    @ViewChildren('timeMenuItems') timeMenuItems!: QueryList<ElementRef>;

    dateTimeFormGroup: FormGroup<{ dateControl: FormControl<Date | null>, timeControl: FormControl<string | null> }>;
    timeSelect: string[][] = [];

    ngOnInit(): void {
        // Create the two digit h and min time string for the start value
        const startTime = String(this.startDate.getHours()).padStart(2, '0') + ':' + String(this.startDate.getMinutes()).padStart(2, '0');
        this.dateTimeFormGroup = new FormGroup({
            dateControl: new FormControl(this.startDate),
            timeControl: new FormControl(startTime)
        });
        // Create the times for the time selection based on the given intervall, while the time is smaller than a day in minutes
        let time = 0;
        let oldHour = -1;
        while (time < 1440) {
            // get the whole number part for the hour
            const hour = (Math.floor(time / 60));
            // if the hour changed add a new array to timeSelect to group the new items
            if (hour !== oldHour) {
                this.timeSelect.push([]);
                oldHour = hour;
            }
            // get the minutes as whole number 
            const minutes = time - (hour * 60);
            // add the two digit h and min time string to the list of possible selections for the newest hour
            this.timeSelect[this.timeSelect.length - 1].push(String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0'));
            // increase the time to the next intervall
            time += this.minuteIntervall;
        }
        this.dateTimeFormGroup.valueChanges.subscribe(() => this.handleSelection());
    }

    /**
     * emit an event when one of the two values changes as a complete date
     */
    handleSelection(): void {
        const date = this.dateTimeFormGroup.value.dateControl;
        const time = this.dateTimeFormGroup.value.timeControl;
        if (date && time) {
            const combinedDateTime = new Date(date);
            const [hours, minutes] = time.split(':');
            combinedDateTime.setHours(+hours, +minutes, 0, 0)
            this.dateTimeEvent.emit(combinedDateTime);
        }
    }

    /**
     * trigger an update of the timeControl when a time is selected in the time select menu
     * @param time the selected time that should be added to the date
     */
    handleTimeSelect(time: string): void {
        this.dateTimeFormGroup.patchValue({ timeControl: time });
    }

    /**
     * the method to determion which time will be marked as active in the time selection menu
     * @param time the time for the button it should be checked
     * @returns true if it is the selected time false else
     */
    timeIsActive(time: string): boolean {
        const timeSelected = this.dateTimeFormGroup.value.timeControl;
        return time === timeSelected;
    }

    /**
     * scrolls to the give active index
     * @param index the index of the active time
     */
    scrollToItem(index: number): void {
        const element = this.timeMenuItems.toArray()[index]?.nativeElement as HTMLElement;
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }

    /**
     * open the time selection menu on the selected time
     * @param menuTrigger the trigger for the menu, so it can be opened manually
     */
    openMenuAndScroll(menuTrigger: MatMenuTrigger): void {
        const timeSelected = this.dateTimeFormGroup.value.timeControl;
        // flatten the timeSelect to get the index of the button that should be scrolled too
        const index = this.timeSelect
            .flat()
            .findIndex((time) => time === timeSelected);
        menuTrigger.openMenu();
        if (index !== -1) {
            //a short delay to let the menu render before scrolling to the item
            setTimeout(() => this.scrollToItem(index), 100);
        }
    }

}
