import { inject, Injectable } from '@angular/core';
import { FileContentDto, RechtlicheInformationenControllerService, RenderedTemplateDto } from '../generated';
import { map, Observable } from 'rxjs';
import { DokumentContent } from '../../../model/dokumente-unternehmen.model';
import { mapFileContentToDokumentContent } from '../mapper/rechtliche-information/rechtliche-information-dto.mapper';

@Injectable({ providedIn: 'root' })
/**
 * service to call RechtlicheInformationenControllerService
 */
export class RechtlicheInformationenApiService {
    private rechtlicheInformationenControllerService = inject(RechtlicheInformationenControllerService);

    /**
     * Service function to get the Impressum HTML template.
     * @returns Observable containing the rendered template
     */
    getImpressum(): Observable<RenderedTemplateDto> {
        return this.rechtlicheInformationenControllerService.getImpressumTemplate().pipe(map((response: unknown) => response as RenderedTemplateDto));
    }

    /**
     * Service function to get the Datenschutz file.
     * @returns Observable containing the file content
     */
    getDatenschutz(): Observable<DokumentContent> {
        return this.rechtlicheInformationenControllerService
            .getDatenschutzFile()
            .pipe(map((response: FileContentDto) => mapFileContentToDokumentContent(response)));
    }

    /**
     * Service function to get the Nutzungsbedingungen file.
     * @returns Observable containing the file content
     */
    getNutzungsbedingungen(): Observable<DokumentContent> {
        return this.rechtlicheInformationenControllerService
            .getNutzungsbedingungenFile()
            .pipe(map((response: FileContentDto) => mapFileContentToDokumentContent(response)));
    }

    /**
     * Service function to get the Nutzungsbedingungen file.
     * @returns Observable containing the file content
     */
    getVollmachtFormular(): Observable<DokumentContent> {
        return this.rechtlicheInformationenControllerService
            .getVollmachtFormularFile()
            .pipe(map((response: FileContentDto) => mapFileContentToDokumentContent(response)));
    }
}
