import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { maintenanceScheduleActions } from "./maintenance-schedule.actions";
import { of, switchMap } from "rxjs";

@Injectable()
export class MaintenanceScheduleEffects {
    private actions$ = inject(Actions);

    //TODO: FFA-658 or FFA-113 add API Call to get the dates
    updateCheckMaintenanceSchedule$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(maintenanceScheduleActions.updateCheckMaintenanceSchedule),
            switchMap(() => {
                return of(maintenanceScheduleActions.setCheckMaintenanceSchedule({
                    maintenanceDate: null,
                }));
            })
        );
    });

    //TODO: FFA-658 or FFA-113 add API Call to update the dates. this effect should navigate away from the page or should open a confirmation if the api call was successful
    updateMaintenanceSchedule$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(maintenanceScheduleActions.updateMaintenanceSchedule),
            switchMap((action) => {
                return of(maintenanceScheduleActions.setCheckMaintenanceSchedule({ maintenanceDate: action.maintenanceDate }));
            }),
        );
    });

}