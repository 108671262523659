<footer class="footer">
    <nav
        *ngIf="(isMaintenance$ | async) !== true"
        class="text-text-light text-footer"
        aria-label="Footer Navigation"
        data-testid="footer-nav-bar">
        <ng-container *ngFor="let footerItem of footerItems; let last = last">
            <a
                *ngIf="footerItem.link === 'datenschutz' || footerItem.link === 'nutzungsbedingungen'; else navigateLink"
                [href]="footerItem.link + '.pdf'"
                (click)="$event.preventDefault(); handleClick(footerItem.link)">
                {{ footerItem.label }}
            </a>
            <ng-template #navigateLink>
                <a [routerLink]="footerItem.link">
                    {{ footerItem.label }}
                </a>
            </ng-template>
            <span *ngIf="!last"> | </span>
        </ng-container>
    </nav>
    <button
        (click)="scrollToTop()"
        aria-label="Knopf um an den Anfang der Seite zu springen"
        data-testid="footer-scroll-button"
        [style]="isWindowScrollable ? '' : 'display:none'">
        <img
            src="./assets/images/svgs/arrow-up_white_1.svg"
            alt="Zum Anfang der Seite"
            class="footer__scroll-to-top__icon" />
    </button>
</footer>
