import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RechtlicheInformationenApiService } from '../../service/api/service/rechtliche-informationen.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { rechtlicheInformationenActions } from './rechtliche-informationen.action';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { DokumentContent } from '../../model/dokumente-unternehmen.model';
import { DokumenteHelperService } from '../../service/helper/dokument.helper.service';

@Injectable()
export class RechtlicheInformationenEffects {
    private actions$ = inject(Actions);
    private rechtlicheInformationenService = inject(RechtlicheInformationenApiService);
    private globalExceptionHandler = inject(GlobalExceptionHandlerService);
    private dokumenteHelperService = inject(DokumenteHelperService);

    updateDatenschutz$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rechtlicheInformationenActions.updateDatenschutz),
                tap(() => {
                    this.rechtlicheInformationenService
                        .getDatenschutz()
                        .pipe(
                            map((fileContent: DokumentContent) => {
                                this.dokumenteHelperService.downloadDokument(fileContent.content, fileContent.name);
                            }),
                            catchError((error) => {
                                this.globalExceptionHandler.handleGeneratedApiError(error);
                                return of(null);
                            }),
                        )
                        .subscribe();
                }),
            );
        },
        { dispatch: false },
    );

    updateNutzungsbedingungen$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rechtlicheInformationenActions.updateNutzungsbedingungen),
                tap(() => {
                    this.rechtlicheInformationenService
                        .getNutzungsbedingungen()
                        .pipe(
                            map((fileContent: DokumentContent) => {
                                this.dokumenteHelperService.downloadDokument(fileContent.content, fileContent.name);
                            }),
                            catchError((error) => {
                                this.globalExceptionHandler.handleGeneratedApiError(error);
                                return of(null);
                            }),
                        )
                        .subscribe();
                }),
            );
        },
        { dispatch: false },
    );

    loadImpressum$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rechtlicheInformationenActions.loadImpressum),
            switchMap(() =>
                this.rechtlicheInformationenService.getImpressum().pipe(
                    map((impressumHtml) => rechtlicheInformationenActions.setImpressumSuccess({ impressumHtml })),
                    catchError((error) => {
                        this.globalExceptionHandler.handleGeneratedApiError(error);
                        return of(rechtlicheInformationenActions.updateFailure({ error }));
                    }),
                ),
            ),
        );
    });

    updateVollmachtFormular$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rechtlicheInformationenActions.updateVollmachtFormular),
                tap(() => {
                    this.rechtlicheInformationenService
                        .getVollmachtFormular()
                        .pipe(
                            map((fileContent: DokumentContent) => {
                                this.dokumenteHelperService.downloadDokument(fileContent.content, fileContent.name);
                            }),
                            catchError((error) => {
                                this.globalExceptionHandler.handleGeneratedApiError(error);
                                return of(null);
                            }),
                        )
                        .subscribe();
                }),
            );
        },
        { dispatch: false },
    );
}
