import { AfterViewInit, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Hub } from 'aws-amplify/utils';

import { authActions } from './store/auth/auth.actions';
import { PersistenceService } from './service/persistence.service';
import { selectFormularStoreIsLoadingAFormular } from './store/formular/formular.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectDokumentContentIsLoading } from './store/dokumente-unternehmen/dokumente-unternehmen.selectors';
import { IdleUserService } from './service/idle-user.service';
import { maintenanceScheduleActions } from './store/maintenance-schedule/maintenance-schedule.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);
    private persistenceService = inject(PersistenceService);
    private idelUserService = inject(IdleUserService);

    authListener = (data: { payload: { event: string } }): void => {
        switch (data.payload.event) {
            case 'signedIn':
                this.store.dispatch(authActions.updateLoginDetails({ isLogin: true }));
                break;
            case 'signedOut':
                break;
            case 'tokenRefresh':
                this.store.dispatch(authActions.updateToken());
                break;
            case 'tokenRefresh_failure':
                this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: true }));
                break;
            case 'signInWithRedirect':
                break;
            case 'signInWithRedirect_failure':
                break;
            case 'customOAuthState':
                break;
            default:
        }
    };

    /**
     * activated listener to authentication events from amplify
     */
    ngOnInit(): void {
        Hub.listen('auth', this.authListener);
        this.persistenceService.init(this.idelUserService.shouldUserBeLoggedOutOnPageLoad());
        this.store.select(selectFormularStoreIsLoadingAFormular).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (isLoading) => {
                document.body.className = isLoading ? 'progress-cursor' : '';
            }
        );
        this.store.select(selectDokumentContentIsLoading).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (isLoading) => {
                document.body.className = isLoading ? 'progress-cursor' : '';
            }
        );
        this.store.dispatch(maintenanceScheduleActions.updateCheckMaintenanceSchedule());
    }
    ngAfterViewInit(): void {
        this.idelUserService.initIdleManagmentForTab(this.destroyRef);
    }

}
