import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectMaintenanceSchedulDateString } from '../../store/maintenance-schedule/maintenance-schedule.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MaintenanceDateString } from '../../model/maintenance-schedule.model';

@Component({
    selector: 'app-maintenance-page',
    templateUrl: './maintenance-page.component.html',
    styleUrl: './maintenance-page.component.scss',
})
export class MaintenancePageComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);
    private router = inject(Router);

    maintenanceDate: MaintenanceDateString;


    ngOnInit(): void {
        this.store.select(selectMaintenanceSchedulDateString).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((maintenanceDate) => {
            if (maintenanceDate === null) {
                this.router.navigate(['/start']);
                return;
            }
            this.maintenanceDate = maintenanceDate;
        });
    }

    /**
     * add a few key words to the time string for better user readability
     * @param dateString the date as a correct formatted string
     * @returns the beautified string
     */
    displayDate(dateString: string): string {
        return dateString.replace(', ', ' um ' );
    }
}
