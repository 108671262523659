<div class="date-time-input-group">
    <p *ngIf="groupLabel !== ''">{{ groupLabel }}:</p>
    <mat-form-field [formGroup]="dateTimeFormGroup">
        <mat-label>{{ dateLabel }}</mat-label>
        <input
            matInput
            formControlName="dateControl"
            [matDatepicker]="dateControl"
            readonly="true"
            class="date-time-input-group__time__input" />
        <mat-datepicker-toggle
            matIconSuffix
            [for]="dateControl"></mat-datepicker-toggle>
        <mat-datepicker #dateControl></mat-datepicker>
    </mat-form-field>
    <mat-form-field [formGroup]="dateTimeFormGroup">
        <mat-label>{{ timeLabel }}</mat-label>
        <input
            matInput
            formControlName="timeControl"
            readonly="true"
            class="date-time-input-group__time__input" />
        <img
            src="/assets/images/svgs/clock-svgrepo-com.svg"
            #timeMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="timeMenu"
            (click)="openMenuAndScroll(timeMenuTrigger)"
            class="date-time-input-group__time__input__svg"
            alt="Zeitauswahl öffnen"
            aria-label="Zeitmenüknopf"
            data-testid="time-menu-button"
            matIconSuffix />
        <mat-menu
            #timeMenu="matMenu"
            aria-label="Zeitauswahl Menü">
            <div class="date-time-input-group__time__menu">
                <div
                    *ngFor="let hour of timeSelect"
                    class="date-time-input-group__time__menu__hour">
                    <button
                        *ngFor="let time of hour"
                        (click)="handleTimeSelect(time)"
                        class="text-label__regular text-text-dark date-time-input-group__time__menu__hour__item"
                        [class]="timeIsActive(time) ? 'text-text-light bg-primary' : ''"
                        #timeMenuItems>
                        {{ time }}
                    </button>
                </div>
            </div>
        </mat-menu>
    </mat-form-field>
</div>
