import { Component, DestroyRef, EventEmitter, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Foerderantrag, Status } from '../../model/foerderantraege.model';
import { foerderantraegeActions } from '../../store/foerderantraege/foerderantraege.actions';
import { TableAction, DISPLAYSTATUS, DataTableConfig, QuickFilterMenuItem } from '../../model/data-table.model';
import {
    selectFoerderantraegeStoreAllFoerderantraegeContent,
    selectFoerderantraegeStoreAllFoerderantraegeTotalElements,
    selectFoerderantraegeStoreReevaluateLoadStatus,
    selectFoerderantraegeStoreTableActions,
    selectFoerderantraegeStoreTableIsLoading,
    selectFoerderantraegeStoreTablePage,
    selectFoerderantraegeStoreTableSize,
    selectFoerderantraegeStoreTableSortingArray,
    selectFoerderartenBezeichnungInUse,
} from '../../store/foerderantraege/foerderantraege.selectors';
import { selectNutzerFoerderbereicheHasFoerderbereich } from '../../store/foerderbereich/foerderbereich.selectors';
import { Observable, filter, first } from 'rxjs';
import { SortParameter } from '../../model/store.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-all-antraege',
    templateUrl: './all-antraege.component.html',
    styleUrl: './all-antraege.component.scss',
})
/**
 * creates the page on which all Anträge will be displayed
 */
export class AllAntraegeComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private store = inject(Store);

    searchValue = '';
    tableConfig: DataTableConfig;
    pageElement = { page: 0, size: 20 };
    statusFilter: string | null = null;
    foerderartFilter = '';

    tableActions$: Observable<TableAction[][]>;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    hasFoerderbereiche$: Observable<boolean>;
    tableContent: Foerderantrag[];

    quickFilterStatusEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterFoerderartEvent: EventEmitter<string> = new EventEmitter<string>();
    statusList: string[] = Object.values(DISPLAYSTATUS);
    foerderartenBezeichnungList: string[] = [];
    quickFilterStatusForm = new FormGroup({
        statusFilterControl: new FormControl('null'),
    });
    quickFilterFoerderartForm = new FormGroup({
        foerderartFilterControl: new FormControl('null'),
    });
    quickFilterMenuItems: QuickFilterMenuItem[];

    ngOnInit(): void {
        this.store.dispatch(foerderantraegeActions.changeAllFoerderantraege());

        this.hasFoerderbereiche$ = this.store.select(selectNutzerFoerderbereicheHasFoerderbereich);
        this.store
            .select(selectFoerderartenBezeichnungInUse)
            .pipe(first((value) => value.length > 0))
            .subscribe((value) => {
                this.foerderartenBezeichnungList = value;
                this.initializeAllAntraegeQuickFilters();
            });

        this.tableActions$ = this.store.select(selectFoerderantraegeStoreTableActions);

        this.tableContent$ = this.store.select(selectFoerderantraegeStoreAllFoerderantraegeContent);
        this.tableIsLoading$ = this.store.select(selectFoerderantraegeStoreTableIsLoading);
        this.tableSortingArray$ = this.store.select(selectFoerderantraegeStoreTableSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectFoerderantraegeStoreTableSize);
        this.tableDisplayedPage$ = this.store.select(selectFoerderantraegeStoreTablePage);
        this.tableTotalElements$ = this.store.select(selectFoerderantraegeStoreAllFoerderantraegeTotalElements);

        this.tableContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableContent = content as Foerderantrag[];
        });

        this.store.select(selectFoerderantraegeStoreReevaluateLoadStatus).pipe(
            takeUntilDestroyed(this.destroyRef),
            filter((reevaluate) => reevaluate)
        ).subscribe(() => {
            this.store.dispatch(foerderantraegeActions.changeAllFoerderantraegeIntern());
        });

        this.quickFilterStatusEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.statusFilterHandler(eventValue);
        });
        this.quickFilterFoerderartEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.foerderartFilterHandler(eventValue);
        });
        this.configChange();
    }

    initializeAllAntraegeQuickFilters(): void {
        this.quickFilterMenuItems = [
            {
                name: 'Status',
                emitter: this.quickFilterStatusEvent,
                formControllerName: 'statusFilterControl',
                formGroup: this.quickFilterStatusForm,
                selectionList: this.statusList,
            },
            {
                name: 'Förderart',
                emitter: this.quickFilterFoerderartEvent,
                formControllerName: 'foerderartFilterControl',
                formGroup: this.quickFilterFoerderartForm,
                selectionList: this.foerderartenBezeichnungList,
            },
        ];
    }

    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'foerderart.bezeichnung', display: 'Förderart' },
                { columnKey: 'antragsnummer', display: 'Antragsnummer' },
                { columnKey: 'foerdernummer', display: 'Fördernummer' },
                { columnKey: 'projektname', display: 'Projektname' },
                { columnKey: 'statusString', display: 'Status' },
                { columnKey: 'aktualisiertAm', display: 'Letzte Aktualisierung' },
            ],
            headerIsSticky: true,
            tableLabel: 'Tabelle aller Anträge des Unternehmens, die der Nutzer sehen darf.',
            tableColumnClass: 'all-antraege',
            tableActions$: this.tableActions$,
            paginationNames: {
                itemsPerPageLabel: 'Anträge pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            tableDataObjectsName: 'Anträge',
            tableDetailId: 'antragsnummer',
        };
    }

    /**
     * Handle the search of the table
     * @param searchEvent The search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(foerderantraegeActions.changeSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        if (sortEvent.sort.column === 'statusString') {
            sortEvent.sort.column = 'status';
        }
        this.store.dispatch(foerderantraegeActions.changeSortingArray({ newSorting: sortEvent.sort, multiSort: sortEvent.isMultiSort }));
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginator. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(foerderantraegeActions.changeShownSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(foerderantraegeActions.changeShownPage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    /**
     * Handles the quick filter status selection
     *
     * @param event holds the string with the quick search status value
     */
    statusFilterHandler(event: string): void {
        const statusEvent = Object.keys(Status).filter((x) => DISPLAYSTATUS[x] === event)[0] as Status;
        if (this.statusFilter !== statusEvent && event !== 'null') {
            this.store.dispatch(foerderantraegeActions.changeShownStatusFilter({ newStatusFilter: statusEvent }));
            this.statusFilter = event.replace(' ', '_').toUpperCase();
            this.pageElement.page = 0;
        } else if (this.statusFilter !== null && event === 'null') {
            this.store.dispatch(foerderantraegeActions.changeShownStatusFilter({ newStatusFilter: null }));
            this.statusFilter = null;
            this.pageElement.page = 0;
        }
    }

    /**
     * Handles the quick filter foerderart selection
     *
     * @param event holds the string with the foerderarten filter
     */
    foerderartFilterHandler(event: string): void {
        if (this.foerderartFilter !== event && event !== 'null') {
            this.store.dispatch(foerderantraegeActions.changeShownFoerderartFilter({ newFoerderartFilter: event }));
            this.foerderartFilter = event;
            this.pageElement.page = 0;
        } else if (this.foerderartFilter !== '' && event === 'null') {
            this.store.dispatch(foerderantraegeActions.changeShownFoerderartFilter({ newFoerderartFilter: '' }));
            this.foerderartFilter = '';
            this.pageElement.page = 0;
        }
    }

    rowClickHandler(index: number): void {
        if (this.tableContent.length > 0) {
            this.router.navigate(['details', this.tableContent[index].antragsnummer], { relativeTo: this.activatedRoute });
        }
    }
}
