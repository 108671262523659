<div class="new-unternehmen-info">
    <p>Für die Beantragung benötigen Sie folgende Unterlagen/ Daten:</p>
    <div class="new-unternehmen-info--section-container">
        <section>
            <p>Als Unternehmen:</p>
            <ul>
                <li>
                    Vollmacht von einer zeichnungsberechtigten Person des/der Fördernehmer*in, Sie können diese
                    <a
                        href="Vollmacht-Formular.pdf"
                        (click)="$event.preventDefault(); handleClick()">
                        Vorlage der FFA
                    </a>
                    nutzen
                </li>
                <li>Handels- oder Vereinsregisterauszug nicht älter als 6 Monate</li>
                <li>Umsatzsteuer-ID</li>
                <li>Sofern Ihr Unternehmen vorsteuerabzugsberechtigt ist: Nachweis der Vorsteuerabzugsberechtigung</li>
            </ul>
        </section>
        <section>
            <p>Als natürliche Person:</p>
            <ul>
                <li>Steueridentifikationsnummer</li>
                <li>Gewerbeschein sofern vorhanden</li>
                <li>Sofern Sie vorsteuerabzugsberechtigt sind: Nachweis der Vorsteuerabzugsberechtigung</li>
            </ul>
        </section>
    </div>
    <p>Wenn Sie Fragen dazu haben, melden Sie sich gerne telefonisch bei der FFA-Hotline 030 27 577 - 599 oder unter portalservice&#64;ffa.de.</p>
</div>
