import { Routes } from '@angular/router';
import { PostkorbComponent } from '../page/postkorb/postkorb.component';
import { AllAntraegeComponent } from '../page/all-antraege/all-antraege.component';
import { PageNotFoundComponent } from '../page/page-not-found/page-not-found.component';
import { StartComponent } from '../page/start/start.component';
import { UnternehmenAdministrationComponent } from '../page/unternehmen-administration/unternehmen-administration.component';
import { AllUnternehmenNutzerComponent } from '../page/all-unternehmen-nutzer/all-unternehmen-nutzer.component';
import { LogoutComponent } from '../page/logout/logout.component';
import { AntragsDetailPageComponent } from '../page/antrags-detail-page/antrags-detail-page.component';
import { FoerderantragDetailResolver } from '../service/resolver/antrags-detail-resolver';
import { AntragCreationComponent } from '../page/antrag-creation/antrag-creation.component';
import { NebenantragsDetailPageComponent } from '../page/nebenantrags-detail-page/nebenantrags-detail-page.component';
import { NebenantragsDetailResolver } from '../service/resolver/nebenantrags-detail-resolver';
import { FormularIframeComponent } from '../page/formular-iframe/formular-iframe.component';
import { LayoutComponent } from '../layout/layout.component';
import { StammdatenComponent } from '../page/stammdaten/stammdaten.component';
import { ImpressumComponent } from '../page/impressum/impressum.component';
import { NutzerProfileComponent } from '../page/nutzer-profile/nutzer-profile.component';
import { MaintenancePageComponent } from '../page/maintenance-page/maintenance-page.component';
import { FfaAdministrationComponent } from '../page/ffa-pages/ffa-administration/ffa-administration.component';
import { MaintenanceManagementComponent } from '../page/ffa-pages/maintenance-management/maintenance-management.component';
import { CsvManagementComponent } from '../page/ffa-pages/csv-management/csv-management.component';

export type Link = {
    label: string;
    link: string;
};

export const appRoutes: Routes = [
    { path: 'formular', component: FormularIframeComponent },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'start',
                component: StartComponent,
            },
            {
                path: 'alle-antraege',
                data: { breadcrumb: { label: 'Anträge stellen und verwalten' } },
                children: [
                    { path: '', component: AllAntraegeComponent },
                    {
                        path: 'details/:antragsnummer',
                        resolve: { breadcrumb: FoerderantragDetailResolver },
                        children: [
                            { path: '', component: AntragsDetailPageComponent },
                            {
                                path: 'nebenantragsart/:id',
                                component: NebenantragsDetailPageComponent,
                                resolve: { breadcrumb: NebenantragsDetailResolver },
                            },
                        ],
                    },
                    { path: 'antragerstellen', data: { breadcrumb: { label: 'Antrag erstellen' } }, component: AntragCreationComponent },
                ],
            },
            {
                path: 'unternehmensverwaltung',
                data: { breadcrumb: { label: 'Unternehmensverwaltung' } },
                children: [
                    { path: '', component: UnternehmenAdministrationComponent },
                    { path: 'alle-benutzer', data: { breadcrumb: { label: 'Alle Benutzer*innen' } }, component: AllUnternehmenNutzerComponent },
                    { path: 'unternehmensstammdaten', data: { breadcrumb: { label: 'Stammdaten' } }, component: StammdatenComponent },
                ],
            },
            {
                path: 'ffa-verwaltung',
                data: { breadcrumb: { label: 'FFA Verwaltung' } },
                children: [
                    { path: '', component: UnternehmenAdministrationComponent },
                    { path: 'alle-benutzer', data: { breadcrumb: { label: 'Alle Benutzer*innen' } }, component: AllUnternehmenNutzerComponent },
                    { path: 'unternehmensstammdaten', data: { breadcrumb: { label: 'Stammdaten' } }, component: StammdatenComponent },
                ],
            },
            {
                path: 'ffaverwaltung', data: { breadcrumb: { label: 'FFA Verwaltung' } },
                children: [
                    { path: '', component: FfaAdministrationComponent },
                    { path: 'csv-management', data: { breadcrumb: { label: 'CSV Management' } }, component: CsvManagementComponent },
                    { path: 'wartungszeitraum-verwalten', data: { breadcrumb: { label: 'Wartungszeitraum verwalten' } }, component: MaintenanceManagementComponent },
                ]
            },
            { path: 'login', redirectTo: 'start', pathMatch: 'full' },
            { path: 'logout', component: LogoutComponent },
            { path: 'postkorb', data: { breadcrumb: { label: 'Postkorb' } }, component: PostkorbComponent },
            { path: 'nutzerprofil', data: { breadcrumb: { label: 'Nutzerprofil' } }, component: NutzerProfileComponent },
            { path: 'impressum', data: { breadcrumb: { label: 'Impressum' } }, component: ImpressumComponent },
            { path: 'wartungsseite', component: MaintenancePageComponent },
            { path: '', redirectTo: 'start', pathMatch: 'full' },
            { path: '**', component: PageNotFoundComponent },
        ],
    },
];
