<section class="antrag-creation--top-section">
    <h1>Förderart wählen</h1>
</section>
<section class="antrag-creation--bottom-section">
    <mat-accordion
        multi="true"
        class="antrag-creation__accordion"
        *ngIf="openFoerderbereiche$ | async; let openFoerderbereiche">
        <ng-container *ngFor="let foerderbereich of foerderbereiche$ | async">
            <mat-expansion-panel
                (opened)="openFoerderbereiche[foerderbereich.bezeichnung] = true"
                (closed)="openFoerderbereiche[foerderbereich.bezeichnung] = false"
                hideToggle
                [attr.data-testid]="foerderbereich.bezeichnung">
                <mat-expansion-panel-header>
                    <mat-panel-title class="!text-text-dark text-accordeon-title">
                        {{ foerderbereich.bezeichnung }}
                    </mat-panel-title>
                    <img
                        *ngIf="!openFoerderbereiche[foerderbereich.bezeichnung]"
                        src="assets/images/svgs/plus-large-svgrepo-com.svg"
                        alt="Akkordion öffnen Icon" />
                    <img
                        *ngIf="openFoerderbereiche[foerderbereich.bezeichnung]"
                        src="assets/images/svgs/minus-svgrepo-com.svg"
                        alt="Akkordion öffnen Icon" />
                </mat-expansion-panel-header>
                <div class="antrag-creation--bottom-section__accordion-body">
                    <ng-container *ngFor="let foerderart of foerderarten$ | async">
                        <ng-container *ngIf="foerderart.foerderbereichBezeichnung === foerderbereich.bezeichnung">
                            <button
                                (click)="navigateToFormular(foerderart.gatewayShortname, foerderart.bezeichnung, foerderart.id)"
                                [attr.data-testid]="foerderart.bezeichnung">
                                <app-card>
                                    <div class="antrag-creation--bottom-section__button-card">
                                        <p class="text-accordeon-card-title">{{ foerderart.bezeichnung }}</p>
                                        <p class="text-plain">{{ foerderart.rechtsgrundlage }}</p>
                                        <p class="text-plain">{{ gueltigBisText(foerderart.gueltigBis) }}</p>
                                    </div>
                                </app-card>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</section>
