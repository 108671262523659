import { inject, Injectable } from "@angular/core";
import { StammdatenFfaControllerService } from "../generated/services";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class FfaStammdatenService {
    private ffaStammdatenApiService = inject(StammdatenFfaControllerService);

    updateFfaStammdaten(csvfiles: File[], separator: string): Observable<void> {
        return this.ffaStammdatenApiService.updateStammdaten({
            formData: {
                csvfiles
            },
            separator
        })
    }
}