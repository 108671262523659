import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, exhaustMap, filter, map, of, switchMap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { displayedDetailsFoerderantragActions, foerderantraegeActions } from './foerderantraege.actions';
import {
    selectDisplayedDetailsFoerderantrag,
    selectFoerderantraegeStoreAllFoerderantraegeContent,
    selectFoerderantraegeStoreComplete,
} from './foerderantraege.selectors';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { routerNavigationAction } from '@ngrx/router-store';
import { UnternehmenFoerderantraegeApiService } from '../../service/api/service/foerderantraege-unternehmen.service';
import { PortalRole } from '../../model/auth.model';
import { selectIsUnternehmenRole, selectRole } from '../auth/auth.selectors';
import { FfaFoerderantraegeApiService } from '../../service/api/service/foerderantraege-ffa.service';
import { NutzerFoerderbereicheApiService } from '../../service/api/service/nutzer-foerderbereiche.service';
import { authActions } from '../auth/auth.actions';
import { AllFoerderantraege, Foerderantrag, RUECKNAHME, WIDERRUFFORMULAR } from '../../model/foerderantraege.model';
import { nutzerRightManagementFoerderantragViewActions } from '../all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { TableAction } from '../../model/data-table.model';
import { AntragHelperService } from '../../service/helper/antrag.helper.service';
import { AntragUnternehmenApiService } from '../../service/api/service/antrag-unternehmen.service';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { formularActionsExt } from '../formular/formular.actions';

@Injectable()
/**
 * The effects corresponding to the foerderantraege Store
 */
export class FoerderantraegeEffects {
    private actions$ = inject(Actions);
    private unternehmenFoerderantraegeAPIService = inject(UnternehmenFoerderantraegeApiService);
    private ffaFoerderantraegeAPIService = inject(FfaFoerderantraegeApiService);
    private store = inject(Store);
    private antragHelperService = inject(AntragHelperService);
    private antragUnternehmenApiService = inject(AntragUnternehmenApiService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private nutzerFoerderbereicheApiService = inject(NutzerFoerderbereicheApiService);

    /**
     * The effect that determens if the company or the ffa endpoint should be called and then calling them for all foerderantraege.
     */
    updateAllFoerderantraege$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                foerderantraegeActions.changeAllFoerderantraege,
                foerderantraegeActions.changeAllFoerderantraegeIntern,
                nutzerRightManagementFoerderantragViewActions.changeAllFoerderantraege,
            ),
            concatLatestFrom(() => [
                this.store.select(selectFoerderantraegeStoreComplete),
                this.store.select(selectIsUnternehmenRole),
                this.store.select(selectActiveUnternehmenId),
            ]),
            switchMap(([, foerderantraegeStore, userHasUnternehmenRole, unternehmenId]) => {
                let returnObservable$: Observable<AllFoerderantraege>;
                if (userHasUnternehmenRole) {
                    returnObservable$ = this.unternehmenFoerderantraegeAPIService.getAllFoerderantraege(foerderantraegeStore, unternehmenId);
                } else {
                    returnObservable$ = this.ffaFoerderantraegeAPIService.getAllFoerderantraege(foerderantraegeStore);
                }
                return returnObservable$.pipe(
                    map((allFoerderantraege) =>
                        foerderantraegeActions.updateAllFoerderantraegeTableActions({ allFoerderantraege: allFoerderantraege })),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(foerderantraegeActions.changeAllFoerderantraegeFailed());
                    }),
                );
            }),
        );
    });

    /**
     * The effect to create the tableActions for the new table content
     */
    updateAllFoerderantraegeTableActions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderantraegeActions.updateAllFoerderantraegeTableActions),
            concatLatestFrom(() => [this.store.select(selectRole)]),
            map(([action, userRole]) => {
                const tableContent: Foerderantrag[] = action.allFoerderantraege.content;
                const newTableActions: TableAction[][] = [];
                const actions = this.antragHelperService.generateActions(tableContent, userRole ?? PortalRole.COMPANY_SELECT);
                for (const actionSet of actions) {
                    newTableActions.push([
                        { name: 'Details anzeigen', isLink: true, link: ['details'], addId: 1 },
                        ...actionSet.filter(action => !action.disabled).map((action): TableAction => ({
                            name: action.name,
                            clickHandler: action.clickHandler,
                            isButton: true,
                            isLink: false,
                        }))]);
                }
                return foerderantraegeActions.setAllFoerderantraege({ allFoerderantraege: action.allFoerderantraege, newTableActions });
            }),
        );
    });

    /**
     * The effect to set a antrag up for deletion
     */
    updateAntragsStatusForDeletion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderantraegeActions.changeAntragsStatusForDeletion),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId)]),
            switchMap(([action, unternehmenId]) => {
                return this.antragUnternehmenApiService.deleteAntragUnternehmen(action.antragsId, unternehmenId).pipe(
                    map(() => foerderantraegeActions.changeAllFoerderantraegeIntern()),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(foerderantraegeActions.changeAllFoerderantraegeFailed());
                    }),
                );
            }),
        )
    });

    /**
     * When these actions occurre the allFoerderantraege part of the store should be reloaded
     */
    changeIsLoading$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                foerderantraegeActions.changeSearchParameter,
                foerderantraegeActions.changeShownFoerderartFilter,
                foerderantraegeActions.changeShownPage,
                foerderantraegeActions.changeShownSize,
                foerderantraegeActions.changeShownStatusFilter,
                foerderantraegeActions.changeSortingArray,
            ),
            exhaustMap(() => {
                return of(foerderantraegeActions.changeAllFoerderantraegeIntern());
            }),
        );
    });

    /**
     * The effect that determens if the company or the ffa endpoint should be called and then calling them for a single foerderantrag.
     */
    updateDisplayedDetailsFoerderantrag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(displayedDetailsFoerderantragActions.updateDisplayedDetailsFoerderantrag),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId), this.store.select(selectIsUnternehmenRole)]),
            switchMap(([action, unternehmenId, userHasUnternehmenRole]) => {
                let returnObservable$: Observable<Foerderantrag>;
                if (userHasUnternehmenRole) {
                    returnObservable$ = this.unternehmenFoerderantraegeAPIService.getFoerderantragById(action.antragsId, unternehmenId);
                } else {
                    returnObservable$ = this.ffaFoerderantraegeAPIService.getFoerderantragById(action.antragsId);
                }
                return returnObservable$.pipe(
                    map((foerderantrag) =>
                        displayedDetailsFoerderantragActions.setDisplayedDetailsFoerderantrag({ newFoerderantrag: foerderantrag }),
                    ),
                );
            }),
        );
    });

    changeReloadDataForSubmittedFormular$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsExt.changeReloadDataForSubmittedFormular),
            concatLatestFrom(() => [this.store.select(selectDisplayedDetailsFoerderantrag)]),
            filter(([action, displayedFoerderantrag]) => (
                (
                    displayedFoerderantrag !== null
                    && (
                        action.antragsId === displayedFoerderantrag.id
                        || action.antragsnummer.startsWith(RUECKNAHME.gatewayShortname)
                        || action.antragsnummer.startsWith(WIDERRUFFORMULAR.gatewayShortname)
                    )
                )
            )),
            switchMap(([action, displayedFoerderantrag]) => {
                return of(displayedDetailsFoerderantragActions.updateDisplayedDetailsFoerderantrag({ antragsId: displayedFoerderantrag ? displayedFoerderantrag.id : action.antragsId }))
            }),
        );
    });

    /**
     * The effect to update the list of foerderarten names in use.
     */

    updateFoerderartenBezeichnungInUse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderantraegeActions.changeAllFoerderantraege),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId), this.store.select(selectIsUnternehmenRole)]),
            switchMap(([, unternehmenId, userHasUnternehmenRole]) => {
                if (userHasUnternehmenRole) {
                    return this.nutzerFoerderbereicheApiService.getFoerderartenInUse(unternehmenId).pipe(
                        map((foerderartenBezeichnungInUse) => {
                            return foerderantraegeActions.setFoerderartenInUse({
                                newFoerderartenInUse: foerderartenBezeichnungInUse,
                            });
                        }),
                    );
                } else {
                    return of(foerderantraegeActions.setFoerderartenInUse({ newFoerderartenInUse: [] }));
                }
            }),
        );
    });

    /**
     * The effect that either resets the store or triggers a reload depending on what the starting page for the selected Company
     */
    handleUnternehmenChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authActions.changeUserRole),
            concatLatestFrom(() => this.store.select(selectRole)),
            switchMap(([, userRole]) => {
                if (userRole === PortalRole.COMPANY_ADMIN) {
                    return of(foerderantraegeActions.resetStore());
                } else {
                    return of(foerderantraegeActions.changeAllFoerderantraege());
                }
            }),
        );
    });

    /**
     * The effect that triggers the loading of one foerderantrag when the route to the page is hit.
     */
    setRouteData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routerNavigationAction),
            concatLatestFrom(() => [
                this.store.select(selectFoerderantraegeStoreAllFoerderantraegeContent),
                this.store.select(selectDisplayedDetailsFoerderantrag),
            ]),
            filter(
                ([{ payload }, , displayed]) =>
                    payload.routerState.url.startsWith('/alle-antraege/details') &&
                    !payload.routerState.url.includes('nebenantragsart') &&
                    !displayed,
            ),
            switchMap(([{ payload }, foerderantraege]) => {
                const antragsnummer = payload.event.url.replace('/alle-antraege/details/', '');
                const antrag = foerderantraege.find((foerderantrag) => foerderantrag.antragsnummer === antragsnummer);
                const antragsId = antrag ? antrag.id : 0;
                return of(displayedDetailsFoerderantragActions.updateDisplayedDetailsFoerderantrag({ antragsId: antragsId }));
            }),
        );
    });

}
