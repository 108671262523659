<amplify-authenticator
    [loginMechanisms]="['email']"
    [services]="getServices()"
    *ngIf="(isLoggedIn$ | async) === false; else loggedInTemplate">
    <!-- Login -->

    <ng-template amplifySlot="sign-in-header">
        <h1 class="amplify-heading">Anmelden</h1>
    </ng-template>

    <!-- Passwort Reset -->

    <ng-template amplifySlot="forgot-password-header">
        <h1 class="amplify-heading">Passwort zurücksetzen</h1>
    </ng-template>

    <ng-template amplifySlot="confirm-reset-password-header">
        <h1 class="amplify-heading">Passwort zurücksetzen</h1>
    </ng-template>

    <ng-template amplifySlot="confirm-reset-password-footer">
        <div style="text-align: center">
            <button
                (click)="authenticator.toSignIn()"
                class="amplify-button amplify-button--link amplify-button--small amplify-button--fullwidth"
                type="button">
                Zurück zur Anmeldung
            </button>
        </div>
    </ng-template>

    <ng-template amplifySlot="force-new-form-fields">
        <amplify-force-new-password-form-fields></amplify-force-new-password-form-fields>
        <span>
            <p
                class="amplify-hint"
                tippyClassName="tippy__hint"
                ngxTippy="{{ this.passwordHintText }}"
                [tippyProps]="this.passwordHintOptions">
                Passwortvorgaben
            </p>
        </span>
    </ng-template>

    <!-- Sign Up -->

    <ng-template amplifySlot="sign-up-header">
        <h1 class="amplify-heading">Registrieren</h1>
    </ng-template>

    <ng-template
        amplifySlot="sign-up-form-fields"
        let-validationErrors="validationErrors">
        <app-amplify-selector
            id="amplify-field-select1gende"
            name="gender"
            label="Anrede:"
            [items]="anredeOptions">
        </app-amplify-selector>

        <amplify-text-field
            name="custom:titel"
            label="Titel:"
            placeholder="Geben Sie Ihren Titel ein"
            [required]="false">
        </amplify-text-field>

        <amplify-text-field
            name="given_name"
            label="Vorname: *"
            placeholder="Geben Sie Ihren Vornamen ein"
            [required]="true">
        </amplify-text-field>

        <amplify-text-field
            name="family_name"
            label="Nachname: *"
            placeholder="Geben Sie Ihren Nachnamen ein"
            [required]="true">
        </amplify-text-field>

        <app-amplify-phone-number-field
            name="phone_number"
            label="Mobilnummer: *"
            placeholder="Geben Sie Ihre Mobilnummer ein"
            [required]="true"
            defaultCountryCode="+49">
        </app-amplify-phone-number-field>

        <amplify-text-field
            name="email"
            label="E-Mail: *"
            placeholder="Geben Sie Ihre E-Mail ein"
            [required]="true">
        </amplify-text-field>

        <app-amplify-password-field
            name="password"
            label="Passwort: *"
            placeholder="Geben Sie Ihr Passwort ein"
            [required]="true"
            (setBlur)="passwordFieldTouchHandler()">
        </app-amplify-password-field>

        <app-amplify-password-field
            name="confirm_password"
            label="Passwort bestätigen: *"
            placeholder="Bitte bestätigen Sie Ihr Passwort"
            [required]="true"
            [hasError]="!!validationErrors['passwordCheck']">
        </app-amplify-password-field>

        <span>
            <p
                class="amplify-hint"
                tippyClassName="tippy__hint"
                ngxTippy="{{ this.passwordHintText }}"
                [tippyProps]="this.passwordHintOptions">
                Passwortvorgaben
            </p>
        </span>

        <amplify-checkbox
            name="custom:acknowledgement"
            value="yes">
            Ich stimme den
            <a
                class="amplify-link"
                href="/nutzungsbedingungen"
                (click)="$event.preventDefault(); $event.stopPropagation(); handleLinkClick('nutzungsbedingungen')">
                Nutzungsbedingungen
            </a>
            zu. *
        </amplify-checkbox>

        <amplify-checkbox
            name="custom:dataprivacy"
            value="yes">
            Ich stimme den
            <a
                class="amplify-link"
                href="/datenschutz"
                (click)="$event.preventDefault(); $event.stopPropagation(); handleLinkClick('datenschutz')">
                Datenschutzrichtlinien
            </a>
            zu. *
        </amplify-checkbox>
        <span><p class="amplify-hint">* Feld muss ausgefüllt werden.</p></span>
        <span *ngIf="passwordFieldTouched && !!validationErrors['passwordCheck']">
            <div class="amplify-error-container">
                <p class="amplify-error-message">{{ validationErrors['passwordCheck'] }}</p>
            </div>
        </span>
    </ng-template>
</amplify-authenticator>

<!-- After Login -->
<ng-template #loggedInTemplate>
    <ng-container *ngIf="handleIsMaintenance(isMaintenance$ | async)"></ng-container>
    <div class="login__loggedIn">
        <h2 class="text-h1 text-accent-primary">Willkommen {{ loggedInUserFullname$ | async }}</h2>
        <app-login-unternehmen-selection></app-login-unternehmen-selection>
        <div>
            <h2 class="text-h3 text-text-dark">Ein neues Unternehmen anlegen</h2>
            <app-button
                [isButton]="true"
                buttonLabel="Unternehmen anlegen"
                (clickEvent)="openCreateUnternehmenDialog()">
            </app-button>
            <p class="text-text-dark">
                Sind Sie bereits registriert und möchten Ihre Stammdaten aktualisieren? Dann wählen Sie bitte Ihr Unternehmen aus und gehen auf
                "Stammdaten" und stellen hier einen Änderungsantrag.
            </p>
        </div>
        <app-button
            [isButton]="true"
            buttonLabel="Abmelden"
            (clickEvent)="logoutUser()">
        </app-button>
    </div>
</ng-template>
