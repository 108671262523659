import { inject, Injectable } from "@angular/core";
import { FoerderantragCommonControllerService } from "../generated";
import { map, Observable } from "rxjs";
import { mapNewAntragInfos } from "../mapper/antraege/antragsnummer-dto.mapper";
import { NewAntragInfos } from "../../../model/foerderantraege.model";

@Injectable({ providedIn: "root" })
export class FoerderantraegeCommonService {
    private foerderantragCommonService = inject(FoerderantragCommonControllerService);

    createFoerderantragForUnternehmensregistrierung(): Observable<NewAntragInfos> {
        return this.foerderantragCommonService.createFoerderantragForUnternehmensregistrierung().pipe(
            map((antragsnummerDTO) => mapNewAntragInfos(antragsnummerDTO))
        );
    }

    updateUnternehmensnameAfterUnternehmensRegistrierung(antragsnummer: string, unternehmensname: string): Observable<boolean> {
        return this.foerderantragCommonService.updateUnternehmensnameAfterUnternehmensRegistrierung({ antragsnummer, requestBody: { unternehmensname } });
    }
}