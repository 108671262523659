<section class="ffa-maintenance-management--top-section">
    <h1>Wartungszeitraum verwalten</h1>
</section>
<section class="ffa-maintenance-management--bottom-section">
    <p>Auf dieser Seite können Sie einen Wartungszeitraum festlegen, worüber anschließend alle Nutzer benachrichtigt werden.</p>
    <div class="ffa-maintenance-management--bottom-section__form">
        <app-date-time-input-group
            [minuteIntervall]="15"
            [startDate]="newMaintenanceDate.maintenanceStartDate"
            groupLabel="Startdatum"
            (dateTimeEvent)="handleDateSelection($event, 'start')"></app-date-time-input-group>
        <app-date-time-input-group
            [minuteIntervall]="15"
            [startDate]="newMaintenanceDate.maintenanceEndDate"
            groupLabel="Enddatum"
            (dateTimeEvent)="handleDateSelection($event, 'end')"></app-date-time-input-group>
    </div>
    <app-button
        [isButton]="true"
        [disabled]="!dateChanged"
        buttonLabel="Wartungszeitraum setzen"
        buttonClass="ffa-maintenance-management--bottom-section__button"
        (clickEvent)="handleSetMaintenance()"></app-button>

    <div
        class="ffa-maintenance-management--bottom-section__active-intervall"
        *ngIf="maintenanceDate !== null">
        <p>Folgender Wartungszeitraum ist aktuell gesetzt:</p>
        <div class="ffa-maintenance-management--bottom-section__active-intervall__time">
            <p>Startdatum:</p>
            <p>{{ activeDate(true) }}</p>
        </div>
        <div class="ffa-maintenance-management--bottom-section__active-intervall__time">
            <p>Enddatum:</p>
            <p>{{ activeDate(false) }}</p>
        </div>
    </div>
</section>
