import { Observable } from 'rxjs';
import { SortParameter } from './store.model';
import { DialogData } from './dialog.model';
import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

export const DISPLAYSTATUS: Record<string, string> = {
    ANTRAG_ANGELEGT: 'Antrag angelegt',
    WIRD_EINGEREICHT: 'Wird eingereicht',
    ANTRAG_EINGEREICHT: 'Antrag eingereicht',
    PRUEFUNG: 'In Prüfung',
    PRUEFUNG_ERNEUT: 'Erneute Prüfung',
    ANTRAG_ZURUECKGEZOGEN: 'Antrag zurückgezogen',
    ANTRAG_DATENNACHFORDERUNG: 'Antrag Datennachforderung',
    KOMMISSION: 'Kommission',
    KOMMISSION_DATENNACHREICHUNG: 'Kommision Datennachreichung',
    ABLEHNUNG: 'Antrag abgelehnt',
    ZUSAGE_PRUEFUNG: 'Zusage Prüfung',
    ANTRAG_BEWILLIGT: 'Antrag bewilligt',
    VERWENDUNGSNACHWEISPRUEFUNG: 'Verwendungsnachweisprüfung',
    WIDERSPRUCH: 'Widerspruch',
    TILGUNG: 'Tilgung',
    ABGESCHLOSSEN: 'Abgeschlossen',
    ABHOLBEREIT: 'Antrag eingereicht',
    ABGEHOLT: 'Antrag eingereicht',
    DATENUEBERNAHME: 'Daten werden übernommen',
    DATENUEBERNAHME_ABGESCHLOSSEN: 'Daten übernommen',
};

export type TableColumn = {
    columnKey: string;
    display: string;
};

export type TableAction = {
    name: string;
    isLink: boolean;
    isExternalLink?: boolean;
    isDialog?: boolean;
    dialogData?: DialogData;
    addId?: number;
    link?: string[];
    isButton?: boolean;
    clickHandler?: () => void;
    overlaySelector?: string;
    overlayConfig?: Record<string, string>;
};

export type PaginatorNameing = {
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
};

export type DataTableConfig = {
    columns: TableColumn[];
    disableTableActions?: boolean;
    headerIsSticky?: boolean;
    paginationNames: PaginatorNameing;
    tableLabel: string;
    tableColumnClass: string;
    tableActions$: Observable<TableAction[][]>;
    tableDataObjectsName: string;
    tableDetailId: string;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableRowClassFunction?: (index: number) => string;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
};

export interface DataTablePageDefaults<T> {
    content: T[];
    page: number;
    size: number;
    searchparameter: string;
    sort: SortParameter[];
    totalElements: number;
    totalPages: number;
    numberOfElements: number;
    isLoading: boolean;
}


export type QuickFilterMenuItem = {
    name: string;
    emitter: EventEmitter<string>;
    formControllerName: string;
    formGroup: FormGroup;
    selectionList: string[];
}

export type DateConfig = {
    hour12: boolean,
    day: '2-digit' | 'numeric',
    month: '2-digit' | 'numeric',
    year: '2-digit' | 'numeric',
    hour: '2-digit' | 'numeric',
    minute: '2-digit' | 'numeric',
    second?: '2-digit' | 'numeric',
};

export const DEFAULT_DATE_STRING_CONFIG_NO_SECONDS: DateConfig = {
    hour12: false,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
}

export const DEFAULT_DATE_STRING_CONFIG: DateConfig = {
    ...DEFAULT_DATE_STRING_CONFIG_NO_SECONDS,
    second: '2-digit',
}