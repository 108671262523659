import { Component, HostListener, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-formular-init-failed-snack-bar',
    templateUrl: './formular-init-failed-snack-bar.component.html',
    styleUrl: './formular-init-failed-snack-bar.component.scss',
})
export class FormularInitFailedSnackBarComponent {
    private snackBarRef = inject(MatSnackBarRef);

    email = "portalservice@ffa.de"

    @HostListener('document:keydown.esc')
    closeSnackBar(): void {
        this.snackBarRef.dismiss();
    }
}
