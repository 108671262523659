import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthStore } from '../../model/auth.model';
import { selectIsUnternehmenRole, selectLoginDetails } from '../../store/auth/auth.selectors';
import { selectMaintenanceIsActive } from '../../store/maintenance-schedule/maintenance-schedule.selectors';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
    private store = inject(Store);

    userHasUnternehmenRole$: Observable<boolean>;
    loggedInUser$: Observable<AuthStore>;
    isMaintenance$: Observable<boolean>;

    /**
     *  initialises observables for the required info from the various stores and checks whether a user was logged in in the last session and set the corresponding information in the stores
     */
    ngOnInit(): void {
        this.userHasUnternehmenRole$ = this.store.select(selectIsUnternehmenRole);
        this.loggedInUser$ = this.store.select(selectLoginDetails);
        this.isMaintenance$ = this.store.select(selectMaintenanceIsActive);
    }
}
