import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const csvManagementActions = createActionGroup({
    source: 'csv-management',
    events: {
        changeSendFilesToServer: props<{ csvFiles: File[], separator: string }>(),
        setServerResponseFailed: props<{ displayMessage: string }>(),
        setServerResponseSuccess: emptyProps(),
        resetServerResponse: emptyProps(),
    }
})