import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NebenantraegeStore, Nebenantragsart } from '../../model/nebenantraege.model';
import { DEFAULT_DATE_STRING_CONFIG, DISPLAYSTATUS } from '../../model/data-table.model';
import { selectDisplayedDetailsFoerderantrag } from '../foerderantraege/foerderantraege.selectors';
import { RUECKNAHME, WIDERRUFFORMULAR } from '../../model/foerderantraege.model';

export const selectNebenantraegeStore = createFeatureSelector<NebenantraegeStore>('nebenantraegeStore');

export const selectNebenantraegeStoreComplete = createSelector(selectNebenantraegeStore, (nebenantraegeStore) => nebenantraegeStore);

export const selectNebenantraegeIsLoading = createSelector(selectNebenantraegeStore, (nebenantraegeStore) => nebenantraegeStore.isLoading);

export const selectNebenantraegeContentPage = createSelector(selectNebenantraegeStore, (nebenantraegeStore) => nebenantraegeStore.nebenantraegeContentPage);

export const selectNebenantraegeTableModifiers = createSelector(
    selectNebenantraegeStore,
    (nebenantraegeStore) => nebenantraegeStore.nebenantraegeTableModifiers,
);

export const selectNebenantragsartenCreateable = createSelector(
    selectNebenantraegeStore,
    selectDisplayedDetailsFoerderantrag,
    (nebenantraegeStore, displayedFoerderantrag) => {
        let nebenantragsartenCreateable = nebenantraegeStore.nebenantragsartenCreateable;
        if (displayedFoerderantrag?.hasPendingRuecknahme) {
            nebenantragsartenCreateable = nebenantragsartenCreateable.filter((nebenantragsart) => nebenantragsart.id !== RUECKNAHME.id);
        }
        if (displayedFoerderantrag?.hasPendingWiderspruch) {
            nebenantragsartenCreateable = nebenantragsartenCreateable.filter((nebenantragsart) => nebenantragsart.id !== WIDERRUFFORMULAR.id);
        }
        return nebenantragsartenCreateable
    },
);

export const selectNebenantragsartenCreateableIds = createSelector(
    selectNebenantragsartenCreateable,
    (nebenantragsartenCreateable) => nebenantragsartenCreateable.map((nebenantragsart => nebenantragsart.id)),
);

export const selectNebenantragsartenExisting = createSelector(
    selectNebenantraegeStore,
    (nebenantraegeStore) => nebenantraegeStore.nebenantragsartenExisting,
);

export const selectNebenantragsartenCreateableAndExisting = createSelector(selectNebenantragsartenCreateable, selectNebenantragsartenExisting, (nebenantragsartenCreateable, nebenantragsartenExisting) => ({
    nebenantragsartenCreateable,
    nebenantragsartenExisting,
}));

export const selectNebenantragsFoerderantragsId = createSelector(
    selectNebenantraegeStore,
    (nebenantraegeStore) => nebenantraegeStore.foerderantragsId,
);

export const selectNebenantragsShownTab = createSelector(
    selectNebenantraegeStore,
    (nebenantraegeStore) => nebenantraegeStore.shownTab,
);

export const selectDisplayedNebenantragsarten = createSelector(selectNebenantragsartenCreateable, selectNebenantragsartenExisting, selectNebenantragsartenCreateableIds, (nebenantragsartenCreateable, nebenantragsartenExisting, nebenantragsartenCreateableIds) => {
    const displayedNebenantragsarten = [...nebenantragsartenCreateable.map((nebenantragsart) => ({ ...nebenantragsart, amount: 0 } as Nebenantragsart))];
    nebenantragsartenExisting.map((nebenantragsart) => {
        const index = nebenantragsartenCreateableIds.indexOf(nebenantragsart.id);
        if (index !== -1) {
            displayedNebenantragsarten[index].amount = nebenantragsart.amount
        } else {
            displayedNebenantragsarten.push(nebenantragsart);
        }
    })
    displayedNebenantragsarten.sort((a, b) => (a.sortierPosition ?? 0) - (b.sortierPosition ?? 0));
    return displayedNebenantragsarten;
});

export const selectNebenantraegeTableContent = createSelector(selectNebenantraegeContentPage, (nebenantraegeContentPage) => {
    return nebenantraegeContentPage.nebenantraegeDetails.map((content) => ({
        ...content,
        aktualisiertAm: new Date(content.aktualisiertAm).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG),
        statusString: DISPLAYSTATUS[content.status],
    }))
});

export const selectNebenantraegeTableSize = createSelector(
    selectNebenantraegeTableModifiers,
    (tableModifiers) => tableModifiers.size,
);

export const selectNebenantraegeTablePage = createSelector(
    selectNebenantraegeTableModifiers,
    (tableModifiers) => tableModifiers.page,
);

export const selectNebenantraegeTableSortingArray = createSelector(
    selectNebenantraegeTableModifiers,
    (tableModifiers) => tableModifiers.sortingArray.map((sortParameter) => {
        const sortParameterCopy = { ...sortParameter }
        if (sortParameterCopy.column === 'status') {
            sortParameterCopy.column = 'statusString';
        }
        return sortParameterCopy;
    }),
);

export const selectNebenantraegeTableTotalElement = createSelector(selectNebenantraegeContentPage, (nebenantraegeContentPage) => nebenantraegeContentPage.totalElements);

export const selectNebenantraegeTableActions = createSelector(selectNebenantraegeTableModifiers, (tableModifiers) => {
    return tableModifiers.nebenantragsActions;
});

export const selectNebenantraegeTableReevaluateReloadStatus = createSelector(selectNebenantraegeStore, (nebenantraegeStore) => nebenantraegeStore.reevaluateLoadStatus);

export const selectDisplayedNebenantragsart = createSelector(selectNebenantraegeStore, (nebenantraegeStore) => nebenantraegeStore.displayedNebenantragsart);

export const selectDisplayedNebenantragsartIsCreateable = createSelector(selectDisplayedNebenantragsart, selectNebenantragsartenCreateableIds, (displayedNebenantrag, createableNebensantraege) => createableNebensantraege.includes(displayedNebenantrag.id));