<section class="csv-management--top-section">
    <h1>CSV Dateiverwaltung</h1>
    <p>Hier können die CSV Dateien für die Förderbereiche, Förderarten und Nebenantragsarten verwaltet werden.</p>
    <p>
        Hierbei ist zu beachten, dass die Dateien folgenden Namen haben müssen: <br />
        foerderart.csv, foerderbereich.csv, freigabe.csv und nebenantragsart.csv
    </p>
</section>
<section
    *ngIf="(userPortalRole$ | async) === neededUserRole"
    class="csv-management--bottom-section">
    <form
        [formGroup]="csvUploadFormGroup"
        class="csv-management--bottom-section--form">
        <!-- Separator Input -->
        <div class="csv-management--bottom-section--form__separator">
            <mat-form-field>
                <mat-label>Spaltenseparator festlegen</mat-label>
                <input
                    matInput
                    formControlName="separator"
                    maxlength="1"
                    placeholder="Gib das Separatorzeichen ein" />
            </mat-form-field>
            <p
                class="csv-management--bottom-section--form__separator__hint"
                tippyClassName="tippy__hint"
                ngxTippy="Beispiel: , oder ;"
                [tippyProps]="{ placement: 'top-start', theme: 'light-border' }">
                <img
                    src="/assets/images/svgs/question-mark-in-a-circle-outline-svgrepo-com.svg"
                    class="csv-management--bottom-section--form__separator__hint__icon"
                    alt="Hinweis zum Separator" />
            </p>
        </div>
        <input
            type="file"
            multiple
            (change)="onFileSelected($event)"
            #fileInput
            hidden />

        <app-button
            class="csv-management--bottom-section__button"
            [buttonLabel]="'Dateien auswählen'"
            [isButton]="true"
            (click)="fileInput.click()">
        </app-button>
        <mat-error
            *ngIf="errorMessageValidation"
            class="csv-management--bottom-section--form__error-message"
            >{{ errorMessageValidation }}
        </mat-error>

        <ng-container
            *ngIf="validFiles.length > 0"
            class="csv-management--bottom-section--form__file-list">
            <p mat-subheader>Ausgewählte Dateien:</p>

            <ng-container *ngFor="let file of validFiles">
                <app-card class="csv-management--bottom-section--form__file-list__item">
                    <div class="csv-management--bottom-section--form__file-list__item__card">
                        <p class="text-accordeon-card-title">{{ file.name }}</p>
                        <app-button
                            [buttonLabel]="'Datei entfernen'"
                            [isButton]="true"
                            (click)="removeValidFile(file.name)">
                        </app-button>
                    </div>
                </app-card>
            </ng-container>
        </ng-container>
    </form>
    <div class="csv-management--bottom-section__actions">
        <app-button
            class="csv-management--bottom-section__button"
            [buttonLabel]="'Dateien anwenden'"
            [isButton]="true"
            [disabled]="formIsInvalid()"
            (click)="triggerFileTransfer()">
        </app-button>
        <app-button
            [buttonLabel]="'Alle Dateien entfernen'"
            [isButton]="true"
            [disabled]="validFiles.length === 0"
            (click)="removeValidFile()">
        </app-button>
    </div>
    <div *ngIf="(filesAreProcessing$ | async) === false && (csvFileHandlingStatus$ | async) === false && uploadTriggered">
        <p class="text-validation-error">Fehler in der Verarbeitung der CSV Dateien.</p>
        <p class="text-validation-error">Bitte beheben sie folgende Fehler:</p>
        <div *ngIf="errorMessageServer$ | async; let errorMessageServer">
            <p
                class="text-validation-error"
                [innerHTML]="errorMessageServer | safePostkorbNachrichtenHtml">
                {{ errorMessageServer }}
            </p>
        </div>
    </div>
    <ng-container *ngIf="(filesAreProcessing$ | async) === true">
        <div class="csv-management--bottom-section__loading-spinner">
            <mat-spinner></mat-spinner>
            <p>Die Dateien werden verarbeitet</p>
        </div>
    </ng-container>

    <div *ngIf="(filesAreProcessing$ | async) === false && (csvFileHandlingStatus$ | async) === true && uploadTriggered">
        <p>Die CSV Dateien wurden erfolgreich geupdated.</p>
    </div>
</section>
