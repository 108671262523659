import { createReducer, on } from "@ngrx/store";
import { CsvStore } from "../../model/csv-management.model";
import { csvManagementActions } from "./csv-management.actions";
import { authActions } from "../auth/auth.actions";

export const csvManagementInitialSate: CsvStore = {
    serverValidationMessage: '',
    serverValidationStatus: false,
    isProcessing: false,
}

export const csvManagementReducer = createReducer(
    csvManagementInitialSate,
    on(csvManagementActions.changeSendFilesToServer, (state): CsvStore => ({ ...state, isProcessing: true })),
    on(csvManagementActions.setServerResponseFailed, (state, action): CsvStore =>
    ({
        ...state,
        serverValidationMessage: action.displayMessage,
        serverValidationStatus: false,
        isProcessing: false
    })
    ),
    on(csvManagementActions.setServerResponseSuccess, (state): CsvStore =>
    ({
        ...state,
        serverValidationMessage: '',
        serverValidationStatus: true,
        isProcessing: false
    })
    ),
    on(csvManagementActions.resetServerResponse, (): CsvStore => ({ ...csvManagementInitialSate })),
    on(authActions.changeUserToLoggedOut, (): CsvStore => ({ ...csvManagementInitialSate })),
);