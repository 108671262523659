import { Component, DestroyRef, EventEmitter, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';

import { Status } from '../../model/foerderantraege.model';
import { selectDisplayedDetailsFoerderantragProjektname } from '../../store/foerderantraege/foerderantraege.selectors';
import { Observable, filter, first } from 'rxjs';
import { DISPLAYSTATUS, DataTableConfig, QuickFilterMenuItem, TableAction } from '../../model/data-table.model';
import { nebenantraegeActions } from '../../store/nebenantraege/nebenantraege.actions';
import { SortParameter } from '../../model/store.model';
import { Nebenantragsart } from '../../model/nebenantraege.model';
import {
    selectNebenantraegeIsLoading,
    selectNebenantraegeTableContent,
    selectNebenantraegeTablePage,
    selectNebenantraegeTableSize,
    selectNebenantraegeTableSortingArray,
    selectNebenantraegeTableTotalElement,
    selectNebenantraegeTableActions,
    selectDisplayedNebenantragsart,
    selectDisplayedNebenantragsartIsCreateable,
    selectNebenantraegeTableReevaluateReloadStatus,
} from '../../store/nebenantraege/nebenantraege.selectors';
import { formularActionsInt } from '../../store/formular/formular.actions';
import { AntragsType } from '../../model/formular.model';
import { AntragHelperService } from '../../service/helper/antrag.helper.service';

@Component({
    selector: 'app-nebenantrags-detail-page',
    templateUrl: './nebenantrags-detail-page.component.html',
    styleUrl: './nebenantrags-detail-page.component.scss',
})
/**
 * The component that shows all nebenantraege for a given nebenantragsart
 */
export class NebenantragsDetailPageComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private store = inject(Store);
    private activatedRoute = inject(ActivatedRoute);
    private antragHelperService = inject(AntragHelperService);


    nebenantragsartLabel: string;
    isNebenantragCreateable$: Observable<boolean>;

    projectName: Observable<string>;
    displayedNebenantragsart: Nebenantragsart;

    tableConfig: DataTableConfig;

    tableActions$: Observable<TableAction[][]>;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;


    searchParameter = '';
    pageElement = { page: 0, size: 20 };
    statusFilter: string | null = null;

    quickFilterStatusEvent: EventEmitter<string> = new EventEmitter<string>();
    statusList: string[] = Object.values(DISPLAYSTATUS);
    quickFilterStatusForm = new FormGroup({
        statusFilterControl: new FormControl('null'),
    });
    quickFilterMenuItems: QuickFilterMenuItem[] = [
        {
            name: 'Status',
            emitter: this.quickFilterStatusEvent,
            formControllerName: 'statusFilterControl',
            formGroup: this.quickFilterStatusForm,
            selectionList: this.statusList,
        },
    ];

    /**
     * subscribe to the needed functions and get only the observables where needed.
     */
    ngOnInit(): void {
        this.tableIsLoading$ = this.store.select(selectNebenantraegeIsLoading);
        this.tableContent$ = this.store.select(selectNebenantraegeTableContent);
        this.projectName = this.store.select(selectDisplayedDetailsFoerderantragProjektname);
        this.tableSortingArray$ = this.store.select(selectNebenantraegeTableSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectNebenantraegeTableSize);
        this.tableDisplayedPage$ = this.store.select(selectNebenantraegeTablePage);
        this.tableTotalElements$ = this.store.select(selectNebenantraegeTableTotalElement);
        this.tableActions$ = this.store.select(selectNebenantraegeTableActions);
        this.isNebenantragCreateable$ = this.store.select(selectDisplayedNebenantragsartIsCreateable);

        this.store
            .select(selectDisplayedNebenantragsart)
            .pipe(first())
            .subscribe((displayedNebenantragsart) => {
                this.displayedNebenantragsart = displayedNebenantragsart
            });

        this.store.select(selectNebenantraegeTableReevaluateReloadStatus).pipe(
            takeUntilDestroyed(this.destroyRef),
            filter((reevaluate) => reevaluate)
        ).subscribe(() => {
            this.store.dispatch(nebenantraegeActions.changeNebenantraegeContentPage());
        });

        this.quickFilterStatusEvent.pipe(takeUntilDestroyed(this.destroyRef)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.statusFilterHandler(eventValue);
        });

        this.nebenantragsartLabel = this.activatedRoute.snapshot.data['breadcrumb'].label.split('"')[1];
        this.configChange();
    }


    /**
     * Create the table configuration that is passed to the generic table component.
     */
    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'antragsnummer', display: 'Antragsnummer' },
                { columnKey: 'statusString', display: 'Status' },
                { columnKey: 'aktualisiertAm', display: 'Letzte Aktualisierung' },
            ],
            headerIsSticky: true,
            tableLabel: 'Tabelle aller Nebenanträge des Unternehmens, für den ausgewählten Förderantrag.',
            tableColumnClass: 'nebenantrag',
            tableActions$: this.tableActions$,
            paginationNames: {
                itemsPerPageLabel: 'Anträge pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            tableDataObjectsName: 'Anträge',
            tableDetailId: 'antragsnummer',
        };
    }


    /**
     * Creates a new formular and opens it in a new tab.
     */
    navigateToFormular(): void {
        this.store.dispatch(formularActionsInt.postChangeCreateNewFormular({
            shortName: this.displayedNebenantragsart.gatewayShortname,
            antragsType: AntragsType.Nebenantrag,
            antragsArtId: this.displayedNebenantragsart.id,
            antragsart: this.displayedNebenantragsart.bezeichnung.replaceAll(" ", "")
        }));
    }

    /**
     * Handle the search input.
     *
     * @param searchEvent holds the search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchParameter !== searchEvent) {
            this.store.dispatch(nebenantraegeActions.changeNebenantraegeTableModifiersSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        if (sortEvent.sort.column === 'statusString') {
            sortEvent.sort.column = 'status';
        }
        this.store.dispatch(
            nebenantraegeActions.changeNebenantraegeTableModifiersSortingArray({
                newSortingParameter: sortEvent.sort,
                isMultiSort: sortEvent.isMultiSort,
            }),
        );
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginator. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(nebenantraegeActions.changeNebenantraegeTableModifiersSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(nebenantraegeActions.changeNebenantraegeTableModifiersPage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    /**
     * Handles the quick filter status selection
     *
     * @param event holds the string with the quick search status value
     */
    statusFilterHandler(event: string): void {
        const statusEvent = Object.keys(Status).filter((x) => DISPLAYSTATUS[x] === event)[0] as Status;
        if (this.statusFilter !== statusEvent && event !== 'null') {
            this.store.dispatch(
                nebenantraegeActions.changeNebenantraegeTableModifiersStatusFilter({ newStatusFilter: statusEvent }),
            );
            this.statusFilter = event.replace(' ', '_').toUpperCase();
            this.pageElement.page = 0;
        } else if (this.statusFilter !== null && event === 'null') {
            this.store.dispatch(nebenantraegeActions.changeNebenantraegeTableModifiersStatusFilter({ newStatusFilter: null }));
            this.statusFilter = null;
            this.pageElement.page = 0;
        }
    }
    /**
     * Get the label for the create button
     * @param nebenantragsart the nebenantragsart for which the label should be created
     * @returns the label
     */
    getNewAntragLabel(nebenantragsart: Nebenantragsart): string {
        return this.antragHelperService.nebenantragCreateNewButtonLabel(nebenantragsart);
    }
}
