import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { Foerderantrag, Status } from '../../../model/foerderantraege.model';
import { Store } from '@ngrx/store';

import { selectDisplayedDetailsFoerderantragFoerderantragsTabActionButtonModifiers } from '../../../store/foerderantraege/foerderantraege.selectors';
import { PortalRole } from '../../../model/auth.model';
import { DEFAULT_DATE_STRING_CONFIG, DISPLAYSTATUS } from '../../../model/data-table.model';
import { DisplayedButtonActions } from '../../../model/antrags-detail-tab.model';
import { AntragHelperService } from '../../../service/helper/antrag.helper.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

@Component({
    selector: 'app-antrags-detail-tab',
    templateUrl: './antrags-detail-tab.component.html',
    styleUrl: './antrags-detail-tab.component.scss',
})
/**
 * The tab component for the first tab of the antrags detail page.
 */
export class AntragsDetailTabComponent implements OnInit, OnDestroy {
    private store = inject(Store);
    private antragHelperService = inject(AntragHelperService);
    private destroyRef = inject(DestroyRef);
    private fristBeginTimeout: NodeJS.Timeout;
    private fristEndTimeout: NodeJS.Timeout;

    displayStatus = DISPLAYSTATUS;
    userRole: PortalRole;
    displayedFoerderantrag: Foerderantrag = {
        id: 0,
        status: Status.ANTRAG_ANGELEGT,
        antragsteller: '',
        gatewayId: '',
        formularShortname: '',
        fmsUuid: '',
        antragsnummer: '',
        foerdernummer: '',
        aktualisiertAm: '',
        aktualisiertVon: '',
        'foerderart.bezeichnung': '',
        projektname: '',
        eingereichtAm: '',
        hasPendingRuecknahme: false,
        hasPendingWiderspruch: false,
    };
    displayedButtonActions: DisplayedButtonActions[];
    foerderantragStatus: Status;

    /**
     * Get the needed Observables from the store
     */
    ngOnInit(): void {
        this.store.select(selectDisplayedDetailsFoerderantragFoerderantragsTabActionButtonModifiers).pipe(
            takeUntilDestroyed(this.destroyRef),
            filter((value) => !!value.foerderantrag && !!value.userRole)
        ).subscribe((value) => {
            this.userRole = value.userRole as PortalRole;
            this.displayedFoerderantrag = value.foerderantrag as Foerderantrag;
            this.foerderantragStatus = this.displayedFoerderantrag.status;
            this.setDisplayedButtonActions();
            this.setFristTimeouts(this.displayedFoerderantrag);

        });
    }

    /**
     * Clear the timeouts
     */
    ngOnDestroy(): void {
        clearTimeout(this.fristBeginTimeout);
        clearTimeout(this.fristEndTimeout);
    }

    /**
     * This function creates the possible actions for the bottom half of the tab
     */
    setDisplayedButtonActions(): void {
        this.displayedButtonActions = [];
        const actions = this.antragHelperService.generateActions([this.displayedFoerderantrag], this.userRole);
        this.displayedButtonActions.push(...actions[0]);
        while (this.displayedButtonActions.length % 3 !== 0) {
            this.displayedButtonActions.push({
                hidden: true,
                name: '',
                clickHandler: () => undefined,
                disabled: true,
            });
        }
    }

    /**
     * This function sets the timeouts for the frist begin and frist end dates
     * @param antrag The antrag that the timeouts should be set for
     */
    setFristTimeouts(antrag: Foerderantrag): void {
        const timeNow = Date.now();
        if (antrag.fristFreischaltung !== undefined) {
            const fristBeginDate = Date.parse(antrag.fristFreischaltung);
            if (fristBeginDate > timeNow) {
                this.fristBeginTimeout = setTimeout(() => {
                    this.setDisplayedButtonActions();
                }, fristBeginDate - timeNow);
            }
        }
        if (antrag.fristEnde !== undefined) {
            const fristEndDate = Date.parse(antrag.fristEnde);
            if (fristEndDate > timeNow) {
                this.fristEndTimeout = setTimeout(() => {
                    this.setDisplayedButtonActions();
                }, fristEndDate - timeNow);
            }
        }
    }

    /**
     * This functions adds the placing to the class of the button depending on its position in the rows of three buttons
     * @param index The index of the button in the list
     * @returns the position in its field
     */
    getPosition(index: number): string {
        const elementPosition = (index + 1) % 3;
        switch (elementPosition) {
            case 1:
                return 'first';
            case 2:
                return 'center';
            default:
                return 'last';
        }
    }

    /**
     * Creates a date in german localisation
     * @param dateString the string that should be turned to a date
     * @returns the localized date string
     */
    displayDate(dateString: string, eingereicht = false): string {
        if (eingereicht && dateString === '') {
            return 'Noch nicht eingereicht'
        }
        return new Date(dateString).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG);
    }
}
