<section class="starting-page--top-section">
    <h1>Willkommen auf dem FFA-Serviceportal!</h1>
    <h2 class="text-h2 text-accent-primary">Hier können Sie Anträge erstellen, verwalten und einreichen.</h2>
</section>
<section class="starting-page--bottom-section">
    <section class="starting-page--bottom-section--left-side">
        <div class="starting-page--bottom-section--left-side__text-area">
            <p class="text-label__regular">
                Willkommen auf dem FFA-Serviceportal – Ihrer zentralen Plattform für die Erstellung, Einreichung und Verwaltung von Anträgen. Dieses
                Portal bietet Ihnen eine Oberfläche, mit der Sie Anträge schnell und effizient bearbeiten können.
            </p>
            <p class="text-label__regular">
                Mit nur wenigen Klicks können Sie neue Anträge erstellen, vorhandene Anträge bearbeiten, den Status Ihrer Einreichungen überprüfen und
                erforderliche Dokumente hochladen.
            </p>
            <p class="text-label">
                Fragen zur Registrierung?
                <br />
                Unser IT-Team ist für Sie da
                <br />
                FFA-Hotline: 030 27 577 - 599
                <br />
                Montag bis Freitag von 9 bis 15 Uhr
            </p>
        </div>
    </section>
    <section class="starting-page--bottom-section--right-side">
        <app-login></app-login>
    </section>
</section>
