import { FormFileType } from "./aws-service.model";
import { Status } from "./foerderantraege.model";

export type FormularStore = {
    formularUrl: string;
    document: File | null;
    partsMax: number;
    partsUploaded: number;
    showProgressBar: boolean;
    activeAntragsnummer: string;
    activeAntragsId: number;
    formContent: FormField[];
    loadOnlyStammdaten: boolean;
    isFormularInitialized: boolean;
    isNewUnternehmen: boolean;
    isLoadingAFormular: boolean;
    numberOfClosedFileUploads: number;
    groupFileUploadsFailed: boolean;
    antragCanBeSubmitted: boolean;
    antragWasSubmitted: boolean;
}

export type FormField = {
    id: string;
    value: string;
    type: string;
    readonly: boolean;
    hId: string;
    hGroupId: string;
}


export enum PayloadType {
    FileUpload = "fileUpload",
    SaveJSON = "saveJSON",
    Script = "script",
    Antragsnummer = "antragsnummer",
    ImportJSON = "importJSON",
    FormularListenersReady = "formularListenersReady",
    TriggerMultiFileUpload = "triggerMultiFileUpload",
    ExternalSaveClick = "externalSaveClick",
    SubmitSuccess = "submitSuccess",
    PageTitleChanged = "titleHasChanged"
}

// Payload Formular --> FE

export type FileUploadPayload = {
    type: PayloadType.FileUpload;
    fileData: File;
    formFileType: FormFileType;
    htmlFieldId: string;
}

export type SaveJSONPayload = {
    type: PayloadType.SaveJSON;
    fileData: string;
    formFileType: FormFileType;
    htmlFieldId: string;
    status: string;
}


export type FormularInitializedPayload = {
    type: PayloadType.FormularListenersReady;
}

export type SubmitSuccessPayload = {
    type: PayloadType.SubmitSuccess;
}

export type TriggerMultiFileUploadPayload = {
    type: PayloadType.TriggerMultiFileUpload;
    filesToUpload: number;
}

export type PageTitleChangedPayload = {
    type: PayloadType.PageTitleChanged;
    newTitle: string;
}

export type PayloadFromFormular = FileUploadPayload | SaveJSONPayload | FormularInitializedPayload | TriggerMultiFileUploadPayload | SubmitSuccessPayload | PageTitleChangedPayload;

// Payload FE --> Formular

export type ScriptPayload = {
    type: PayloadType.Script;
    content: string;
}

export type AntragsnummerPayload = {
    type: PayloadType.Antragsnummer;
    content: string;
}

export type ImportJSONPayload = {
    type: PayloadType.ImportJSON;
    content: FormField[];
}

export type PayloadFromFrontend = ScriptPayload | AntragsnummerPayload | ImportJSONPayload | ExternalSaveClick;

export type ExternalSaveClick = {
    type: PayloadType.ExternalSaveClick;
    content: "save" | "submit" | "checkJson";
}

export enum AntragsType {
    Foerderantrag,
    Nebenantrag
}

export type FormJson = {
    bolForm: {
        formContent: FormContent[]
    }
}
export type FormContent = {
    id: string;
    value: string;
    type: string;
    readonly: boolean;
}

export const ANTRAGCANBESUBMITTEDSTATUS: Status[] = [Status.ANTRAG_ANGELEGT, Status.ANTRAG_DATENNACHFORDERUNG, Status.KOMMISSION_DATENNACHREICHUNG];

export type JsonValidation = {
    noFileInJson: boolean;
    antragsnummerTheSame: boolean;
}