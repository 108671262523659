import { PaginatedResponseFoerderantragDto } from "../../generated";

/**
 * Maps the generated PageFoerderantragDto to the store used AllFoerderantraege
 * @param allFoerderantraegeExt the api call returned object
 * @returns returns a store friendly page object
 */
export const mapFoerderantragToFoerderantragId = (allFoerderantraegeExt: Required<PaginatedResponseFoerderantragDto>): number => {
    return allFoerderantraegeExt.content.map((foerderantrag) => foerderantrag.id)[0];
}
