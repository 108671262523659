import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    override getFirstDayOfWeek(): number {
        return 1;
    }
    // here the es-lint rules get overwritten, since a native object is overwritten that needs for the overwrite to work these inputs
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
    override format(date: Date, displayFormat: Object): string {
        if (!date) return '';

        return new Intl.DateTimeFormat('de-DE', {
            hour12: false,        // 24-hour format
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);
    }
}