<div class="formular-init-failed-snack-bar">
    <ng-container>
        <div class="formular-init-failed-snack-bar__content">
            <div class="formular-init-failed-snack-bar__content__message">
                <h1 class="text-h2">Formular Initialisierung fehlgeschlagen</h1>
                <p class="text-plain">
                    Sehr geehrte/r Antragsteller/in, Ihre aktuelle Browsereinstellung führt dazu, dass das Formular nicht fehlerfrei ausgefüllt und an
                    die FFA übertragen werden kann. Bitte nutzen Sie einen anderen Browser, um das Formular auszufüllen. Schließen Sie dafür den
                    Browser und öffnen Sie das Serviceportal im neuen Browser und melden sich dort erneut an. Wenn Sie Fragen haben oder Unterstützung
                    benötigen, melden Sie sich bitte: <br /><br />FFA-Hotline: 030 27 577 - 599<br />Montag bis Freitag von 9 bis 15 Uhr
                    <br /><br />oder per E-Mail: {{ email }}
                </p>
            </div>
            <app-button
                class="formular-init-failed-snack-bar__content__button"
                [isButton]="true"
                buttonLabel="Schließen"
                (clickEvent)="closeSnackBar()"></app-button>
        </div>
    </ng-container>
</div>
