import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { MaintenanceDate } from "../../model/maintenance-schedule.model";

export const maintenanceScheduleActions = createActionGroup({
    source: 'Maintenance Schedule',
    events: {
        //the action to check if there is an active window.
        updateCheckMaintenanceSchedule: emptyProps(),
        setCheckMaintenanceSchedule: props<{ maintenanceDate: MaintenanceDate | null }>(),
        //the action to call the api and set a new maintenance window for the admin
        updateMaintenanceSchedule: props<{ maintenanceDate: MaintenanceDate | null }>(),
    }
});