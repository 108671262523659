import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaintenanceDate } from '../../../model/maintenance-schedule.model';
import { selectMaintenanceSchedulDate } from '../../../store/maintenance-schedule/maintenance-schedule.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { maintenanceScheduleActions } from '../../../store/maintenance-schedule/maintenance-schedule.actions';
import { DEFAULT_DATE_STRING_CONFIG_NO_SECONDS } from '../../../model/data-table.model';

@Component({
    selector: 'app-maintenance-management',
    templateUrl: './maintenance-management.component.html',
    styleUrl: './maintenance-management.component.scss',
})
export class MaintenanceManagementComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    newMaintenanceDate: MaintenanceDate;

    maintenanceDate: MaintenanceDate | null;
    dateChanged = false;

    ngOnInit(): void {
        this.store.select(selectMaintenanceSchedulDate).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((maintenanceDate) => {
            this.maintenanceDate = maintenanceDate;
            this.dateChanged = false;
        });
        this.newMaintenanceDate = this.maintenanceDate ? this.maintenanceDate : { maintenanceStartDate: new Date(), maintenanceEndDate: new Date() };
    }

    /**
     * set the date  from the date time input group to the correct maintenanceDate
     * @param date the selected date
     * @param dateType if its the start or end time for the maintenance
     */
    handleDateSelection(date: Date, dateType: 'start' | 'end'): void {
        this.dateChanged = true;
        if (dateType === 'start') {
            this.newMaintenanceDate = { ...this.newMaintenanceDate, maintenanceStartDate: date };
        } else {
            this.newMaintenanceDate = { ...this.newMaintenanceDate, maintenanceEndDate: date };
        }
    }

    /**
     * trigger the setting of the maintenance window for the user
     */
    handleSetMaintenance(): void {
        this.store.dispatch(maintenanceScheduleActions.updateMaintenanceSchedule({ maintenanceDate: this.newMaintenanceDate }));
    }

    activeDate(isStart: boolean): string {
        if (this.maintenanceDate) {
            return isStart ?
                this.maintenanceDate.maintenanceStartDate.toLocaleString("de-DE", DEFAULT_DATE_STRING_CONFIG_NO_SECONDS) :
                this.maintenanceDate.maintenanceEndDate.toLocaleString("de-DE", DEFAULT_DATE_STRING_CONFIG_NO_SECONDS);
        }
        return "";
    }
}
