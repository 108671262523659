import { Component, HostListener, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'app-invalid-formular-snack-bar',
    templateUrl: './invalid-formular-snack-bar.component.html',
    styleUrl: './invalid-formular-snack-bar.component.scss',
})
export class InvalidFormularSnackBarComponent {
    private snackBarRef = inject(MatSnackBarRef);
    private router = inject(Router);

    @HostListener('document:keydown.esc')
    returnToAlleAntraege(): void {
        this.snackBarRef.dismiss();
        this.router.navigate(['/alle-antraege']);
    }
}
