import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MaintenanceDateString, MaintenanceScheduleStore } from "../../model/maintenance-schedule.model";
import { selectRole } from "../auth/auth.selectors";
import { PortalRole } from "../../model/auth.model";
import { DEFAULT_DATE_STRING_CONFIG_NO_SECONDS } from "../../model/data-table.model";

export const selectMaintenanceScheduleStore = createFeatureSelector<MaintenanceScheduleStore>('maintenanceScheduleStore');

export const selectMaintenanceSchedulDate = createSelector(
    selectMaintenanceScheduleStore,
    (maintenanceScheduleStore) => maintenanceScheduleStore.maintenanceDate
);

export const selectMaintenanceSchedulDateString = createSelector(
    selectMaintenanceSchedulDate,
    (maintenanceDate) => {
        if (maintenanceDate) {
            return {
                maintenanceStartDate: maintenanceDate.maintenanceStartDate.toLocaleString("de-DE", DEFAULT_DATE_STRING_CONFIG_NO_SECONDS),
                maintenanceEndDate: maintenanceDate.maintenanceEndDate.toLocaleString("de-DE", DEFAULT_DATE_STRING_CONFIG_NO_SECONDS),
            } as MaintenanceDateString
        }
        return null;
    }
);

export const selectMaintenanceIsActive = createSelector(
    selectMaintenanceScheduleStore,
    selectRole,
    (maintenanceScheduleStore, userRole) => {
        if (userRole === PortalRole.FFA_ADMIN) {
            return false;
        }
        return maintenanceScheduleStore.maintenanceIsActive;
    }
);
