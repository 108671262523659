import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';

import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { SearchComponent } from './search/search.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableActionMenuComponent } from './data-table/data-table-action-menu/data-table-action-menu.component';
import { QuickFilterComponent } from './quick-filter/quick-filter.component';
import { OpenDialogComponent } from './open-dialog/open-dialog.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DialogComponent } from './dialog/dialog.component';
import { OpenDialogContentDirective } from '../directives/open-dialog-content.directive';
import { RightManagementFoerderbereichComponent } from './open-dialog/right-management-foerderbereich/right-management-foerderbereich.component';
import { PostkorbNachrichtDialogComponent } from './open-dialog/postkorb-nachricht/postkorb-nachricht-dialog.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { RightManagementFoerderantragComponent } from './open-dialog/right-management-foerderantrag/right-management-foerderantrag.component';
import { RightManagementAllantraegeComponent } from './open-dialog/right-management-allantraege/right-management-allantraege.component';
import { AddNewBenutzerComponent } from './open-dialog/add-new-benutzer/add-new-benutzer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RolleManagementComponent } from './open-dialog/rolle-management/rolle-management.component';
import { CustomMatPaginatorIntl } from '../service/custom-provider/custom-mat-pagination-intl-provider.service';
import { LesebestaetigungSetzenComponent } from './open-dialog/lesebestaetigung-setzen/lesebestaetigung-setzen.component';
import { SafeTextHtmlPipe } from '../service/pipe/safe-text-html';
import { LogoutSnackBarComponent } from './snack-bar/logout-snack-bar/logout-snack-bar.component';
import { InvalidFormularSnackBarComponent } from './snack-bar/invalid-formular-snack-bar/invalid-formular-snack-bar.component';
import { NewUnternehmenInfoComponent } from './open-dialog/new-unternehmen-info/new-unternehmen-info.component';
import { FormularInitFailedSnackBarComponent } from './snack-bar/formular-init-failed-snack-bar/formular-init-failed-snack-bar.component';
import { DateTimeInputGroupComponent } from './date-time-input-group/date-time-input-group.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from '../service/helper/custom-date-adapter.service';
import { GenerateFormularInfoComponent } from './open-dialog/generate-formular-info/generate-formular-info.component';

@NgModule({
    declarations: [
        ButtonComponent,
        CardComponent,
        SearchComponent,
        DataTableComponent,
        DataTableActionMenuComponent,
        QuickFilterComponent,
        ProgressBarComponent,
        DialogComponent,
        OpenDialogComponent,
        OpenDialogContentDirective,
        RightManagementFoerderbereichComponent,
        PostkorbNachrichtDialogComponent,
        SnackBarComponent,
        LogoutSnackBarComponent,
        RightManagementFoerderantragComponent,
        RightManagementAllantraegeComponent,
        AddNewBenutzerComponent,
        RolleManagementComponent,
        LesebestaetigungSetzenComponent,
        NewUnternehmenInfoComponent,
        InvalidFormularSnackBarComponent,
        FormularInitFailedSnackBarComponent,
        DateTimeInputGroupComponent,
        GenerateFormularInfoComponent,
        SafeTextHtmlPipe,
    ],
    imports: [
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatPaginatorModule,
        MatSelectModule,
        RouterModule,
        BrowserModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        PdfViewerModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
    ],
    providers: [
        CustomMatPaginatorIntl,
        { provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    exports: [
        ButtonComponent,
        CardComponent,
        SearchComponent,
        DataTableComponent,
        QuickFilterComponent,
        ProgressBarComponent,
        DialogComponent,
        SnackBarComponent,
        LogoutSnackBarComponent,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        DateTimeInputGroupComponent,
        MatListModule,
        SafeTextHtmlPipe,
    ],
})
export class UiModule { }
