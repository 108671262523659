<section class="ffa-administration--top-section">
    <p>Als FFA-Admin stehen ihnen folgende Aktionen zur Auswahl</p>
</section>
<section class="ffa-administration--bottom-section mt-5">
    <a routerLink="/ffaverwaltung/wartungszeitraum-verwalten">
        <app-card> <p>Wartungsdaten verwalten</p> </app-card>
    </a>
    <a routerLink="/ffaverwaltung/csv-management">
        <app-card> <p>CSV Daten verwalten</p> </app-card>
    </a>
</section>
