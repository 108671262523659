import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { rechtlicheInformationenActions } from '../../../store/rechtliche-informationen/rechtliche-informationen.action';

@Component({
    selector: 'app-new-unternehmen-info',
    templateUrl: './new-unternehmen-info.component.html',
    styleUrl: './new-unternehmen-info.component.scss',
})
export class NewUnternehmenInfoComponent {
    private store = inject(Store)

    handleClick(): void {
        this.store.dispatch(rechtlicheInformationenActions.updateVollmachtFormular());
    }
}
