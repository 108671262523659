import { Component, OnDestroy, OnInit, inject, Renderer2, ViewChild, AfterViewInit, ElementRef, DestroyRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { formularActionsExt, formularActionsInt } from '../../store/formular/formular.actions';
import {
    selectFormularStoreFormularUrl,
    selectFormularStoreShowProgressBar,
    selectFormularStoreCombinedInitializedFormularState,
    selectFormularStoreActiveFormularAntragsnummer,
    selectFormularStoreIsNewUnternehmen,
    selectFormularStoreNumberOfClosedFileUploadsAndSucces,
    selectFormularStoreAntragCanBeSubmitted,
    selectFormularStoreAntragWasSubmitted,
} from '../../store/formular/formular.selectors';
import { FileUploadPayload, SaveJSONPayload, PayloadFromFormular, PayloadFromFrontend, PayloadType, AntragsnummerPayload, ImportJSONPayload, ScriptPayload, FormField, TriggerMultiFileUploadPayload, PageTitleChangedPayload } from '../../model/formular.model';
import { environment } from '../../../environments/environment';
import { errorActions } from '../../store/error-store/error.actions';
import { ErrorTitle } from '../../model/error-store.model';
import { FormularHandlerService } from '../../service/formular-handler.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IdleUserService } from '../../service/idle-user.service';
import { STAMMDATEN } from '../../model/foerderantraege.model';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { SnackBarService } from '../../service/snack-bar.service';

@Component({
    selector: 'app-formular-iframe',
    templateUrl: './formular-iframe.component.html',
    styleUrl: './formular-iframe.component.scss',
})
/**
 * The component that handels the load and display of the formular
 */
export class FormularIframeComponent implements OnInit, OnDestroy, AfterViewInit {
    private store = inject(Store);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private renderer = inject(Renderer2);
    private formularHandlerService = inject(FormularHandlerService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private destroyRef = inject(DestroyRef);
    private idleUserService = inject(IdleUserService);
    private snackBarService = inject(SnackBarService);

    @ViewChild("iframeformular", { static: false }) iFrameFormular: ElementRef<HTMLIFrameElement>;
    formularLink: string;
    showProgressBar$: Observable<boolean>;
    progressBarTitle: string;
    antragsnummer: string;
    isNewUnternehmen: boolean;
    numberOfStartedFileUploads = 0;
    messagePortForFinishedUploads: MessagePort | undefined;
    listOfQueuedFileUploadActions: Action[] = [];
    firstUploadStarted = false;
    numberOfSaveJsonRetrys = 0;
    formularWasSubmitted = false;
    private formularInitFailedTimer: number;

    /**
     * Get the url for the form from the store and set up the listener for messages from the iframe
     */
    ngOnInit(): void {
        this.registerFormularListener();
        this.initializeObservables();
    }

    /**
     * Before the window is unloaded the formular is saved to the bus, to hold the right formular if an reload was triggerd
     *
     * @param event the event that is triggered before the window is unloaded
     */
    @HostListener('window:beforeunload', ['$event'])
    onBeforeUnload(event: BeforeUnloadEvent): void {
        this.store.dispatch(formularActionsInt.postChangeExistingFormularInternal())
        event.preventDefault();
    }

    /**
     * Removes the event listener for messages and subscriptions
     */
    ngOnDestroy(): void {
        window.removeEventListener('message', this.receiveMessage.bind(this), false);
        this.idleUserService.handleTriggerBeforeLogout(false);
        this.store.dispatch(formularActionsInt.resetFormularState());
    }

    /**
     *  we do listen on "load" here because the (load) event in the template triggers sometimes to early
     */
    ngAfterViewInit(): void {
        this.injectAfterIframeIsLoaded();
        this.formularHandlerService.setIframeRef(this.iFrameFormular);
    }

    /**
     * The function to initialize the observables for the formular and remebers them in the subscriptions array
     */
    private initializeObservables(): void {
        this.store.dispatch(formularActionsInt.updateAntragCanBeSubmitted());
        this.showProgressBar$ = this.store.select(selectFormularStoreShowProgressBar);
        this.store.select(selectFormularStoreActiveFormularAntragsnummer).pipe(first(antragsnummer => antragsnummer !== ""))
            .subscribe(antragsnummer => {
                this.antragsnummer = antragsnummer
                if (!antragsnummer.includes(STAMMDATEN.gatewayShortname)) {
                    this.idleUserService.handleTriggerBeforeLogout(true);
                }
                this.store.dispatch(formularActionsInt.initializeNewTab());
            });
        this.store.select(selectFormularStoreFormularUrl).pipe(first(url => url !== "")).subscribe(url => { this.formularLink = url });
        this.store.select(selectFormularStoreIsNewUnternehmen).pipe(first()).subscribe(isNewUnternehmen => {
            this.isNewUnternehmen = isNewUnternehmen;
        });
        this.store.select(selectFormularStoreAntragWasSubmitted).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((antragWasSubmitted) => {
            this.formularWasSubmitted = antragWasSubmitted;
        })

        this.store.select(selectFormularStoreCombinedInitializedFormularState)
            .pipe(
                first(value => !!value)
            ).subscribe(value => {
                if (!value) return
                this.addAntragsnummerToQueryParams(value.activeAntragsnummer);
                this.sendAntragsnummer(value.activeAntragsnummer);
                this.sendFormContent(value.formContent);
            });
        this.store.select(selectFormularStoreNumberOfClosedFileUploadsAndSucces).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (result) => {
                if (result.numberOfClosedFileUploads === this.numberOfStartedFileUploads && this.messagePortForFinishedUploads) {
                    this.messagePortForFinishedUploads.postMessage({ success: !result.groupFileUploadsFailed });
                    this.store.dispatch(formularActionsExt.resetNumberOfClosedFileUploads());
                    this.messagePortForFinishedUploads = undefined;
                    this.numberOfStartedFileUploads = 0;
                    this.firstUploadStarted = false;
                } else if (result.numberOfClosedFileUploads < this.numberOfStartedFileUploads && this.listOfQueuedFileUploadActions.length > 0) {
                    this.store.dispatch(this.listOfQueuedFileUploadActions.shift() as Action);
                }
            });
        this.store.select(selectFormularStoreAntragCanBeSubmitted).pipe(
            takeUntilDestroyed(this.destroyRef),
            filter((canBeSubmitted) => !canBeSubmitted)
        ).subscribe(() => {
            this.snackBarService.openInvalidFormularSnackBar();
        });
    }

    /**
     * The function to register the event listener for messages from the form
     */
    private registerFormularListener(): void {
        window.addEventListener('message', this.receiveMessage.bind(this), false);
    }

    /**
     * The function to inject the script into the form after the iframe is loaded
     */
    private injectAfterIframeIsLoaded(): void {
        this.formularInitFailedTimer = window.setTimeout(() => { this.handleFormularInitFailed() }, environment.FE.ffaGlobalsLoadTimeSeconds * 1000);
        this.renderer.listen(this.iFrameFormular.nativeElement, 'load', () => {
            const payload = {
                type: PayloadType.Script,
                content: environment.FE.url,
            } satisfies ScriptPayload;
            this.sendToFormular(payload);
        });
    }

    /**
     * The function to handel messages from the form
     * @param event the message event that is reveived and is checked
     */
    receiveMessage(event: MessageEvent & { data: PayloadFromFormular }): void {
        const { data, origin, ports } = event;
        const payload: PayloadFromFormular = data;
        if (origin !== environment.FMS.origin) {
            return;
        }
        switch (payload.type) {
            case PayloadType.FileUpload:
                this.handleFileUpload(payload, [...ports]);
                break;
            case PayloadType.SaveJSON:
                this.handleSaveJSON(payload, [...ports]);
                break;
            case PayloadType.FormularListenersReady:
                this.handleFormularInitialized()
                break;
            case PayloadType.TriggerMultiFileUpload:
                this.monitorUploads(payload, [...ports]);
                break;
            case PayloadType.PageTitleChanged:
                this.handlePageTitleChanged(payload);
                break;
            default:
                break;
        }
    }


    /**
     * The function to handle the file upload message from the form
     * @param payload file upload payload that is received from the form
     * @returns
     */
    private handleFileUpload(payload: FileUploadPayload, returnMessagePort: MessagePort[]): void {
        const isValidFileUploadPayload = payload.fileData && payload.formFileType && payload.htmlFieldId;
        if (!isValidFileUploadPayload) {
            return
        }
        this.progressBarTitle = "Lädt hoch...";
        const action = formularActionsExt.changeInitializationFileUpload({
            file: payload.fileData,
            formFileType: payload.formFileType,
            htmlFieldId: payload.htmlFieldId,
            returnMessagePort: returnMessagePort[0],
        })
        // handle the action so that it will deployed one action after the other and not all at the start, so that in the case of a refresh no action gets lost
        if (this.numberOfStartedFileUploads > 0 && !this.firstUploadStarted) {
            this.store.dispatch(action);
            this.firstUploadStarted = true;
        } else if (this.numberOfStartedFileUploads > 0) {
            this.listOfQueuedFileUploadActions.push(action);
        } else {
            this.store.dispatch(action);
        }
    }

    /**
     * The function to handle the save JSON message from the form
     * @param payload
     * @returns
     */
    private handleSaveJSON(payload: SaveJSONPayload, returnMessagePort: MessagePort[]): void {
        const isValidSaveJSONPayload = payload.fileData && payload.formFileType && payload.htmlFieldId && payload.status;
        if (!isValidSaveJSONPayload) {
            return
        }

        // Extract the unternehmensname form the element with id = "Firma.Name"
        if (payload.status === "submit" && this.isNewUnternehmen) {
            try {
                const fileDataJson = JSON.parse(payload.fileData);
                if (fileDataJson?.bolForm?.formContent) {
                    const firmaNameElement = fileDataJson.bolForm.formContent.find((element: { id: string }) => element.id === "Firma.Name");
                    const personTitelElement = fileDataJson.bolForm.formContent.find((element: { id: string }) => element.id === "Person.Titel");
                    const personVornameElement = fileDataJson.bolForm.formContent.find((element: { id: string }) => element.id === "Person.Vorname");
                    const personNameElement = fileDataJson.bolForm.formContent.find((element: { id: string }) => element.id === "Person.Name");
                    const personFirmaElement = fileDataJson.bolForm.formContent.find((element: { id: string }) => element.id === "PersonFirma");
                    if ("natürliche Person" === personFirmaElement.value) {
                        const firmaName = [personTitelElement.value, personVornameElement.value, personNameElement.value].filter(Boolean).join(" ");
                        this.store.dispatch(formularActionsInt.updateUnternehmensname({ unternehmensname: firmaName }));
                    }
                    else if (firmaNameElement) {
                        this.store.dispatch(formularActionsInt.updateUnternehmensname({ unternehmensname: firmaNameElement.value }));
                    }
                }
            } catch (error) {
                this.store.dispatch(errorActions.updateShownError({
                    newError: {
                        status: 1,
                        detail: "Fehler beim verarbeiten des Unternehmensnamens im Formular",
                        instance: "",
                        title: ErrorTitle[1],
                        error: error as Error,
                    }
                }));
            }
        }
        this.progressBarTitle = "Speichern...";
        const jsonValidation = this.formularHandlerService.isValidJsonForSubmit(payload.fileData, this.antragsnummer);
        if (!jsonValidation.antragsnummerTheSame || !jsonValidation.noFileInJson) {
            if (this.numberOfSaveJsonRetrys >= 10) {
                let errorMessage = 'Fehler bei der Einreichung.';
                let errorStatus = 4;
                if (!jsonValidation.antragsnummerTheSame) {
                    errorStatus = 5;
                }
                if (payload.status === 'save') {
                    errorMessage = 'Fehler beim Speichern der JSON.'
                }
                this.formularHandlerService.handleReturnMessage(returnMessagePort[0], false);
                const newError = this.globalExceptionHandlerService.createErrorInformation({
                    status: errorStatus,
                    instance: 'formular-iframe.componente/submit/json/retrycount',
                    title: ErrorTitle[4],
                    detail: 'Zu viele Versuche beim Speichern der JSON',
                    error: new Error(errorMessage),
                });
                this.store.dispatch(errorActions.updateShownError({ newError }));
                return;
            }
            if (!jsonValidation.antragsnummerTheSame) {
                this.addAntragsnummerToQueryParams(this.antragsnummer);
                this.sendAntragsnummer(this.antragsnummer);
            }
            this.store.dispatch(formularActionsExt.setShowProgressBar({ showProgressBar: true }));
            this.numberOfSaveJsonRetrys += 1;
            setTimeout(() => {
                this.formularHandlerService.handleExternalSave(payload.status as "save" | "submit" | "checkJson", () => {
                    this.numberOfSaveJsonRetrys = 0;
                    this.formularHandlerService.handleReturnMessage(returnMessagePort[0], true);
                })
            }, 200);
            return;
        }
        if (payload.status === "checkJson") {
            this.formularHandlerService.handleReturnMessage(returnMessagePort[0], true);
            return;
        }
        const file = this.formularHandlerService.createJsonFile(payload.fileData);
        this.store.dispatch(formularActionsExt.changeInitializationFileUpload({
            file,
            formFileType: payload.formFileType,
            htmlFieldId: payload.htmlFieldId,
            status: payload.status,
            returnMessagePort: returnMessagePort[0],
        }));
    }


    /**
     * Set up everything for the save of the JSON after all files are uploaded
     * @param payload the number of files that should be uploaded
     * @param returnMessagePort the port on which the finished upload should be communicated
     */
    private monitorUploads(payload: TriggerMultiFileUploadPayload, returnMessagePort: MessagePort[]): void {
        this.store.dispatch(formularActionsExt.resetNumberOfClosedFileUploads());
        this.numberOfStartedFileUploads = payload.filesToUpload;
        this.messagePortForFinishedUploads = returnMessagePort[0];
    }

    private handleFormularInitialized(): void {
        window.clearTimeout(this.formularInitFailedTimer);
        this.store.dispatch(formularActionsInt.setListenersReady());
    }

    /**
     * The function to add the antragsnummer to the query params
     * @param antragsnummer
     */
    addAntragsnummerToQueryParams(antragsnummer: string): void {
        const queryParams = { ...this.route.snapshot.queryParams };
        queryParams["antragsnummer"] = antragsnummer;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge', // This will preserve existing query params
            replaceUrl: true
        });
    }

    /**
     * Sends the antragsnummer to the form
     * @param antragsnummer
     * @returns
     */
    private sendAntragsnummer(antragsnummer: string): void {
        if (!antragsnummer) return;

        const newPayload = {
            type: PayloadType.Antragsnummer,
            content: antragsnummer,
        } satisfies AntragsnummerPayload;

        this.sendToFormular(newPayload)
    }

    /**
     * Function to send the form content to the form for prefilled fields and data
     * @param formContent
     */
    sendFormContent(formContent: FormField[]): void {
        const payload = {
            type: PayloadType.ImportJSON,
            content: formContent,
        } satisfies ImportJSONPayload;

        this.sendToFormular(payload);
    }

    private sendToFormular(payload: PayloadFromFrontend): void {
        try {
            this.iFrameFormular.nativeElement.contentWindow?.postMessage(payload, environment.FMS.origin);
        } catch (error) {
            this.store.dispatch(errorActions.updateShownError({
                newError: {
                    status: 1,
                    detail: 'Es ist bei dem Versuch zwischen dem Formular und dem Portal Informationen auszutauschen zu einem Fehler gekommen.',
                    instance: '',
                    title: ErrorTitle[1],
                    possibleSolutionServerSend: ['Bitte laden Sie das Formular neu.'],
                    error: error as Error,
                }
            }));
        }
    }

    private handlePageTitleChanged(payload: PageTitleChangedPayload): void {
        if (payload.newTitle !== '' && this.formularWasSubmitted) {
            this.snackBarService.openInvalidFormularSnackBar();
        }
    }

    private handleFormularInitFailed(): void {
        this.router.navigate(["/alle-antraege"])
        this.snackBarService.openFormularInitFailedSnackBar();
    }
}
