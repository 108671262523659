<div class="invalid-formular-snack-bar">
    <ng-container>
        <div class="invalid-formular-snack-bar__content">
            <div class="invalid-formular-snack-bar__content__message">
                <h1 class="text-h2">Invalides Formular</h1>
                <p class="text-plain">Dieses Formular ist bereits eingereicht und kann nicht mehr bearbeitet werden.</p>
            </div>
            <app-button
                class="invalid-formular-snack-bar__content__button"
                [isButton]="true"
                buttonLabel="Zurück zur Antragsübersicht"
                (clickEvent)="returnToAlleAntraege()"></app-button>
        </div>
    </ng-container>
</div>
