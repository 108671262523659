import { inject, Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { csvManagementActions } from "./csv-management.actions";
import { catchError, filter, map, of, switchMap } from "rxjs";
import { FfaStammdatenService } from "../../service/api/service/ffa-stammdaten.service";
import { Store } from "@ngrx/store";
import { selectRole } from "../auth/auth.selectors";
import { PortalRole } from "../../model/auth.model";
import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";

@Injectable()
export class CsvManagementEffects {
    private actions$ = inject(Actions);
    private ffaStammdatenService = inject(FfaStammdatenService);
    private store = inject(Store);
    private globalExceptionHandler = inject(GlobalExceptionHandlerService);

    updateCsvFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(csvManagementActions.changeSendFilesToServer),
            concatLatestFrom(() => [this.store.select(selectRole)]),
            filter(([, role]) => role === PortalRole.FFA_ADMIN),
            switchMap(([action]) => {
                return this.ffaStammdatenService.updateFfaStammdaten(action.csvFiles, action.separator).pipe(
                    map(() => csvManagementActions.setServerResponseSuccess()),
                    catchError((error) => {
                        if (error.status === 400 && error.body.detail === 'Parsing of CSV file failed') {
                            return of(csvManagementActions.setServerResponseFailed({ displayMessage: error.body.csvParsingHints }));
                        }
                        this.globalExceptionHandler.handleGeneratedApiError(error);
                        return of(csvManagementActions.setServerResponseFailed({ displayMessage: '' }));
                    })
                );
            })
        )
    });
}